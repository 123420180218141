import { createSlice } from '@reduxjs/toolkit';
import { tabel, tabelTeacherPassDates } from '../../../defaultData/admin/tabel/tabel';
import { fetchAdminTabelData } from './asyncActions';

const initialState = {
  adminTabelData: [
    // ...tabel,
  ],
  adminTabelTeacherPassDates: [
    // ...tabelTeacherPassDates
  ],
  adminTabelIsClosed: false,

  adminTabelFilters: {
    city: {id: 0, name: ''},
    school: {id: 0, name: ''},
    classObj: {id: 0, number: '', letter: ''},
    subgroup: {id: 0, name: ''},
    teacher: {id: 0, name: ''},
    program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
    month: {id: 0, label: ''},
    year: new Date().getFullYear(),
    post: {},
  }
};

const adminTabel = createSlice({
  name: 'AdminTabel',
  initialState,
  reducers: {
    setAdminTabelData(state, action) {
      state.adminTabelData = action.payload;      
    },
    setAdminTabelDataLessonEdit(state, action) {
      state.adminTabelData = state.adminTabelData.map(item=>{
        if(item.id === action.payload.id){
          return {...item, lessons: item.lessons.map(child=>(child.date === action.payload.date)? {...child, value: action.payload.value}: child)}          
        }
        else return item
      })
    },

    setAdminTabelDataReplace(state, action) {
      state.adminTabelData = action.payload.tabel;
      state.adminTabelTeacherPassDates = action.payload.passDates;
    },

    setAdminTabelDataTeacherPass(state, action) {
      state.adminTabelTeacherPassDates = (state.adminTabelTeacherPassDates.indexOf(action.payload.date) !== -1)? state.adminTabelTeacherPassDates.filter(date => date !== action.payload.date): [...state.adminTabelTeacherPassDates, action.payload.date]
    },
    setAdminTabelFilters(state, action) {
      state.adminTabelFilters = {...state.adminTabelFilters, ...action.payload}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminTabelData.pending, (state, action) => {
      state.adminTabelData = [];
      state.adminTabelTeacherPassDates = [];
      state.adminTabelIsClosed = false;
    });
    builder.addCase(fetchAdminTabelData.fulfilled, (state, action) => {      
      if(action.payload && action.payload.tabel && action.payload.tabel && Array.isArray(action.payload.tabel)){
        state.adminTabelData = action.payload.tabel;
        state.adminTabelTeacherPassDates = action.payload.passDates;
        state.adminTabelIsClosed = action.payload.isClosed;
      }            
    });
    builder.addCase(fetchAdminTabelData.rejected, (state, action) => {
        state.loading = false;
    });
  },

});

const { actions, reducer } = adminTabel;


export default reducer;

export const { 
  setAdminTabelData,
  setAdminTabelDataLessonEdit,
  setAdminTabelDataTeacherPass,
  setAdminTabelDataReplace,
  setAdminTabelFilters
} = actions;

