import './index.scss'

const aplicationTypeData = (id) =>{
    if(id === 0)
        return { id: 0, name: 'Верификация' }
    else if(id === 1)
        return { id: 1, name: 'Профиль' }
    else if(id === 2)
        return { id: 2, name: 'Документы' }
    else if(id === 3)
        return { id: 3, name: 'Доп программа' }
    else if(id === 4)
        return { id: 4, name: 'Справка' }
    else if(id === 5)
        return { id: 5, name: 'Ребенок' }
    else
        return { id: -1, name: 'Не найден' }

}

const UsersTableElem = ({item, clickFunc, buttonLabel}) =>{
    const {id, name, type, city, school} = item;
    const typeObj = aplicationTypeData(type)
    return (
        <div className={`admin__applications-table-line d-flex justify-between align-center ${name? '': 'd-none'}`}>
            <div className="admin__applications-table-line-item type">
                <div className='admin__applications-table-line-item-type'>{typeObj.name}</div>
            </div>

            <div className="admin__applications-table-line-item name">{name}</div>

            <div className="admin__applications-table-line-item city">{city}</div>

            <div className="admin__applications-table-line-item school">{school}</div>

            <button className="admin__applications-table-line-button follow" onClick={()=>clickFunc(id, typeObj)}>{buttonLabel}</button>
        </div>
    )
}

export default UsersTableElem;