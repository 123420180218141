import './index.scss'
import AdminPopup from './../../Popup/AdminPopup';
import DeleteBlock from './../../Popup/DeleteBlock/DeleteBlock';
import WarningBlock from './../../Popup/WarningBlock/WarningBlock';
import { useDispatch, useSelector } from 'react-redux';
import Header from './../../Header/index';
import { getDateFormatFunc, getPageElems, getPagesMass, tabelDataAddedIndexFunc } from '../../../../services/clientHome';
import { setPopupBack } from '../../../../redux/popup/popupSlice';
import { useState, useEffect } from 'react';
import DefaultTabel from './DefaultTabel/DefaultTabel';
import { selectAdminTabel } from './../../../../redux/admin/tabel/selectors';
import TabelBlock from './TabelBlock/index';
import TabelCalendar from './../../Popup/Tabel/Calendar/index';
import { getWordMonthDate, getPagesCount } from './../../../../services/clientHome';
import PagesBlock from './PagesBlock/index';
import ConfirmCancel from './../../Popup/Tabel/ConfirmCancel/ConfirmCancelBlock';
import { axiosProxyCatchPost } from './../../../../axiosProxy';
import { setAdminTabelDataReplace, setAdminTabelFilters } from '../../../../redux/admin/tabel/tabelSlice';
import { fetchAdminTabelData } from '../../../../redux/admin/tabel/asyncActions';

const Tabel = () =>{
    const dispatch = useDispatch()

    const { 
        adminTabelData,
        adminTabelTeacherPassDates,
        adminTabelFilters,
        adminTabelIsClosed
    } = useSelector(selectAdminTabel);
    
    const [tabelData, setTabelData] = useState(adminTabelData)
    const [tabelTeacherPassDates, setTabelTeacherPassDates] = useState(adminTabelTeacherPassDates)

    const [editingPassDate, setEditingPassDate] = useState({
        date: '',
        isTeacherPass: false,
        comment: ''
    })


    const [calendDeleteDate, setCalendDeleteDate] = useState('')
    const [calendActive, setCalendActive] = useState(false)
    const [calendError, setCalendError] = useState('')
    const [tabelIsEdit, setTabelIsEdit] = useState(false)

    const [popupType, setPopupType] = useState('')
    const [error, setError] = useState('')
    const [page, setPage] = useState(1)
    const [widthSize, setWidthSize] = useState(window.innerWidth)
    const [pages, setPages] = useState({pages: 1, elems: 3});
    const [newDate, setNewDate] = useState({date: '', id: 0});

    const [calendPopupPosition, setCalendPopupPosition] = useState({
        top: 0,
        left: 0,        
        calendDate: '',
        date: '',
        dateId: 0
    })

    // useEffect(()=>{
    //     dispatch(fetchAdminTeachersData({query, count: 30, page: 0, isScrolling: false, filters: filters.post}))
    //     setPage(0)
    // }, [filters])

    useEffect(()=>{
        if(Object.keys(adminTabelFilters.post).length >= 6) dispatch(fetchAdminTabelData({filters: adminTabelFilters.post}))
    }, [adminTabelFilters.post])

    useEffect(()=>{
        if(!calendActive){
            editDateFunc()
        }
    }, [calendActive])

    useEffect(()=>{
        if(adminTabelData && adminTabelData.length && adminTabelData[0].lessons && adminTabelData[0].lessons.length){
            setPages(getPagesCount({dataLength: adminTabelData[0].lessons.length, maxSize: widthSize, elemsSize: 140, minusVal: 776}))
        }
        else{
            setPages({...pages, elems: getPagesCount({dataLength: 1, maxSize: widthSize, elemsSize: 140, minusVal: 776}).elems})
        }              
    }, [adminTabelData])

    useEffect(()=>{
        if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
            let resultPages = getPagesCount({dataLength: tabelData[0].lessons.length, maxSize: widthSize, elemsSize: 140, minusVal: 776})
            setPages(resultPages)
            if(page > resultPages.pages) setPage(resultPages.pages)
        }
        else{
            setPages({pages: 1, elems: 3})
        }
        
    }, [widthSize])

    useEffect(() => {
        dispatch(setAdminTabelFilters({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classObj: {id: 0, number: '', letter: ''},
            subgroup: {id: 0, name: ''},
            program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
            month: {id: 0, label: ''},
            year: new Date().getFullYear(),
            post: {},
        }))

        const resizeHandler = () =>setWidthSize(window.innerWidth);
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    useEffect(()=>{
        setTabelData(adminTabelData)
        setTabelTeacherPassDates(adminTabelTeacherPassDates)
    }, [adminTabelData])

    useEffect(()=>{
        if(JSON.stringify({tabel:tabelData, passDates:tabelTeacherPassDates}) !== JSON.stringify({tabel:adminTabelData, passDates:adminTabelTeacherPassDates}))
            setTabelIsEdit(true)
        else
            setTabelIsEdit(false)            
    }, [tabelData, tabelTeacherPassDates])

    const popupOpenCalendFunc = (elem, date, id=1) => {
        setCalendError('')
        if(!calendActive || calendActive && date !== calendPopupPosition.date){
            let passDates = tabelTeacherPassDates.filter(item=> item.date === date && item.id === id);
            setEditingPassDate((passDates.length)? passDates[0]: {date: date, isTeacherPass: false, comment: ''})            

            const position = elem.getBoundingClientRect()
            let x = position.left + 40;
            let y = position.top + 60;
            if(window.innerWidth < x + 466){
                x -= (window.innerWidth - (x + 466)) * -1;
            }
            if(window.innerHeight < y + 440){
                y -= (window.innerHeight - (y + 440 + 64 + 30)) * -1;
                x += 400 
            }
            setCalendActive(true)            
            setCalendPopupPosition({
                date: date,
                dateId: id,
                calendDate: getDateFormatFunc(date),
                top: y, 
                left: x,
            })
        }
        else{
            setCalendActive(false)
            setCalendPopupPosition({
                ...calendPopupPosition,
                dateId: 0,
                date: ''
            })
        }
    }

    ///tabelTeacherPassDates.filter(item=> !(item.newElem && !item.isTeacherPass)) ///Объект с пропусками преподов для бека(без ненужных изменений)

    const editDateFunc = () =>{        
        setCalendError('')
        if(calendPopupPosition.calendDate && calendPopupPosition.date !== calendPopupPosition.calendDate.toLocaleDateString()){
            let editPassDatelessonsCount = tabelData[0].lessons.filter(item=>(item.date === calendPopupPosition.date)).length;            
            if(tabelTeacherPassDates.filter(item=> item.date === calendPopupPosition.date).length){                
                setTabelTeacherPassDates(tabelTeacherPassDates.map(item=>{                    
                    if(item.date === calendPopupPosition.date){                        
                        if(editPassDatelessonsCount > calendPopupPosition.dateId && item.id > calendPopupPosition.dateId)
                            return{...item, id: item.id-1}
                        else if(calendPopupPosition.date === item.date && item.id === calendPopupPosition.dateId)
                            return{...editingPassDate, date: calendPopupPosition.calendDate.toLocaleDateString(), comment: editingPassDate.isTeacherPass? editingPassDate.comment: '', id: tabelData[0].lessons.filter(item=>(item.date === calendPopupPosition.calendDate.toLocaleDateString())).length? tabelData[0].lessons.filter(item=>(item.date === calendPopupPosition.calendDate.toLocaleDateString())).length+1: 1}
                        else
                            return item
                    }
                    else return item
                }))
            }
            else if(!tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date && item.id === calendPopupPosition.dateId).length && editingPassDate.isTeacherPass){
                setTabelTeacherPassDates([...tabelTeacherPassDates, {...editingPassDate, date:calendPopupPosition.calendDate.toLocaleDateString(), newElem: true, id: calendPopupPosition.dateId}])
            }

            let resultData = tabelData.map(item=>{
                let lessons = item.lessons.map(lesson=>{
                    if(lesson.date === calendPopupPosition.date){                        
                        if(item.lessons.filter(item => item.date === calendPopupPosition.date).length > calendPopupPosition.dateId && lesson.id > calendPopupPosition.dateId)
                            return {...lesson, id: lesson.id-1}
                        else if(lesson.id === calendPopupPosition.dateId)
                            return {...lesson, date: calendPopupPosition.calendDate.toLocaleDateString(), id: item.lessons.filter(item=>(item.date === calendPopupPosition.calendDate.toLocaleDateString())).length? item.lessons.filter(item=>(item.date === calendPopupPosition.calendDate.toLocaleDateString())).length+1: 1}
                        else 
                            return lesson
                    }
                    else return lesson
                }).sort((a, b) => {
                    if(a.date === b.date){
                        let firstDate = getDateFormatFunc(a.date);
                        let secondDate = getDateFormatFunc(b.date);
                        if(a.id > b.id) firstDate.setDate(firstDate.getDate() + 1)
                        else secondDate.setDate(secondDate.getDate() + 1)                  
                    
                        return firstDate - secondDate;
                    }
                    else return getDateFormatFunc(a.date) - getDateFormatFunc(b.date);
                })

                return {...item, lessons}
            })
            
            let editElemPage = getPagesCount({
                dataLength: resultData[0].lessons.findIndex(item=> item.date === calendPopupPosition.calendDate.toLocaleDateString() && item.id === resultData[0].lessons.filter(lesson=>(lesson.date === calendPopupPosition.calendDate.toLocaleDateString())).length) + 1, //editElemNumber
                maxSize: window.innerWidth, 
                elemsSize: 140, 
                minusVal: 776}
            ).pages;
            
            setPage(editElemPage)
            setTabelData(resultData)
            setCalendActive(false)

            let editDatelessonsCount = tabelData[0].lessons.filter(item=>(item.date === calendPopupPosition.calendDate.toLocaleDateString())).length;
            setNewDate({date: calendPopupPosition.calendDate.toLocaleDateString(), id: editDatelessonsCount? editDatelessonsCount+1: 1})
            setTimeout(()=>{
                setNewDate({date: '', id: 0})
            }, 2000)
        }
        else if(tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date && item.id === calendPopupPosition.dateId).length){
            setTabelTeacherPassDates(tabelTeacherPassDates.map(item=>{
                if(editingPassDate.date === item.date && item.id === calendPopupPosition.dateId)
                    return {...item, ...editingPassDate, comment: editingPassDate.isTeacherPass? editingPassDate.comment: '', id: calendPopupPosition.dateId}
                else
                    return item
            }))
        }
        else if(!tabelTeacherPassDates.filter(item=> item.date === editingPassDate.date && item.id === calendPopupPosition.dateId).length && editingPassDate.isTeacherPass){
            setTabelTeacherPassDates([...tabelTeacherPassDates, {...editingPassDate, newElem: true, id: calendPopupPosition.dateId}])                   
        }
        setCalendPopupPosition({top: 0, left: 0, calendDate: '', date: '', dateId: 0})    
    }

    const getNewDate = () =>{
        let newDate;
        if(tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
            let dates = tabelData[0].lessons.map(item=> item.date)
            let lastDate = getDateFormatFunc(dates.at(-1))
            newDate = new Date(lastDate)
            newDate.setDate(newDate.getDate() + 1)

            if(newDate.getMonth() !== lastDate.getMonth()){
                let ending = false;
                let result = new Date(lastDate);
                for(let i = 1; !ending; i++){
                    result.setDate(result.getDate() - 1)
                    if(dates.indexOf(result.toLocaleDateString()) === -1 && result.getMonth() === lastDate.getMonth()){
                        newDate = result;
                        ending=true;
                    }
                    else if(i === 31){
                        ending = true;
                        newDate = lastDate;
                    }
                }
            }
        }
        else{
            newDate = getWordMonthDate(adminTabelFilters.month.label, adminTabelFilters.year)
            newDate = newDate? newDate.date: newDate
        }        
        
        return newDate;
    }

    const setCalendPopupPositionFunc = (date) =>{
        setCalendError('')
        setCalendPopupPosition({...calendPopupPosition, calendDate: date})
    }

    const editLessonFunc = (studentId, date, dateId, lessonVal) =>{
        setTabelData(tabelData.map(item=>{
            if(item.id === studentId){
                return {...item, lessons: item.lessons.map(lesson=>{
                    if(lesson.date === date && lesson.id === dateId){
                        return {...lesson, value: lessonVal}
                    }
                    else return lesson
                })}
            }
            else return item;
        }))
    }

    const deleteDateFunc = (date, setLoading) =>{
        if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length && (tabelData[0].lessons.length % pages.elems) === 1){
            if(page === pages.pages) setPage(page-1)
            setPages(getPagesCount({dataLength: tabelData[0].lessons.length-1, maxSize: window.innerWidth, elemsSize: 140, minusVal: 776}))
        }


        setTabelTeacherPassDates(tabelTeacherPassDates.filter(item =>!(item.date === date.date && item.id === date.id)).map(item=>{
            if(item.date === date.date && item.id > date.id){
                return{...item, id: item.id-1}
            }
            else return item
        }))
        
        setTabelData(tabelData.map(item=>{
            let lessons = item.lessons.filter(lesson=> !(lesson.date === date.date && lesson.id === date.id))
            
            if(lessons.filter(lesson=> lesson.date === date.date).length+1 > date.id){
                lessons = lessons.map(lesson=>{
                    if(lesson.date === date.date && lesson.id > date.id)
                        return {...lesson, id: lesson.id - 1}
                    else
                        return lesson
                })
            }            
            return {...item, lessons}
        }))
        setCalendActive(false)
        setPopupType('')
        setLoading(false)
    }

    const createNewDateFunc = () =>{
        let date = getNewDate();
        if(date){
            let resultData = [...tabelData];
            resultData = resultData.map(student=>{
                return {
                    ...student, 
                    lessons: [...(student.lessons? student.lessons: []), {date: date.toLocaleDateString(), value: '', id: (student.lessons && student.lessons.filter(item=>(item.date === date.toLocaleDateString())).length)? student.lessons.filter(item=>(item.date === date.toLocaleDateString())).length + 1: 1}].sort((a, b) => {
                        if(a.date === b.date){
                            let firstDate = getDateFormatFunc(a.date);
                            let secondDate = getDateFormatFunc(b.date);
                            if(a.id > b.id) firstDate.setDate(firstDate.getDate() + 1)
                            else secondDate.setDate(secondDate.getDate() + 1)                  
                        
                            return firstDate - secondDate;
                        }
                        else return getDateFormatFunc(a.date) - getDateFormatFunc(b.date);
                    })
                }
            })
            

            setTabelData(resultData)

            let newElemPage = getPagesCount({
                dataLength: resultData[0].lessons.findIndex(item=> item.date === date.toLocaleDateString() && item.id === resultData[0].lessons.filter(lesson=>(lesson.date === date.toLocaleDateString())).length) + 1, //newElemNumber
                maxSize: window.innerWidth, 
                elemsSize: 140, 
                minusVal: 776}
            ).pages;  

            
            if(tabelData && tabelData.length && tabelData[0].lessons && tabelData[0].lessons.length){
                if((tabelData[0].lessons.length+1) % pages.elems === 1){
                    setPages(getPagesCount({dataLength: tabelData[0].lessons.length+1, maxSize: window.innerWidth, elemsSize: 140, minusVal: 776}))
                }
                setPage(newElemPage)
            }

            let newDatelessonsCount = tabelData[0].lessons? tabelData[0].lessons.filter(item=>(item.date === date.toLocaleDateString())).length: 0;
            setNewDate({date: date.toLocaleDateString(), id: newDatelessonsCount? newDatelessonsCount+1: 1})
            setTimeout(()=>{
                setNewDate('')
            }, 2000)
        }
        else{
            setPopupType('Warning')
            setError('Нельзя создать занятие, достигнуто максимальное колличество занятий в этом месяце.')
        }
    }

    const saveCancelTabelFunc = (setLoading, isSave) =>{
        if(isSave){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/tabel/set-tabel',
                data: {...adminTabelFilters.post, data: JSON.stringify({tabel: tabelData, passDates: tabelTeacherPassDates})},
                resolved: () =>{
                    setLoading(false)
                    dispatch(setAdminTabelDataReplace({tabel: tabelData, passDates: tabelTeacherPassDates}))
                    setPopupType('')
                    setTabelIsEdit(false)
                },
                rejected: (error) =>{
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')
                    setLoading(false)
                    setPopupType('Warning')
                }
            })
        }
        else{
            setTabelData(adminTabelData)
            setTabelTeacherPassDates(adminTabelTeacherPassDates)
            setLoading(false)
            setPopupType('')
        }
    }

    return (
        <div className='admin__tabel-tabel' onClick={(e)=>{
            if(calendActive && !e.target.classList.contains("admin__tabel-table-line-item-date") && e.target.closest(".admin__tabel-calendar-container") === null){
                setCalendActive(false)
                setCalendPopupPosition({...calendPopupPosition, dateActive: ''})                
            }
        }}>
            <Header isDisabledSearch={true}/>
            
            <div className='admin-wrapper'>
                <div className="admin__teachers-top">
                    <div className="admin__teachers-top-title" onClick={()=>{
                        console.log(tabelData);                        
                    }}>
                        Табель
                    </div>
                    
                    <div className='admin__teachers-top-buttons d-flex'>
                        <button className='admin__teachers-top-button d-flex align-center' onClick={()=>{
                            dispatch(setPopupBack('open'))
                            setPopupType('TabelFilters')
                        }}>
                            <div className='admin__teachers-top-button-label'>Фильтры</div>
                            <div className='admin__teachers-top-button-icon filters'/>
                        </button>
                    </div>
                </div>

                {
                    (adminTabelFilters.city.id && adminTabelFilters.school.id && adminTabelFilters.classObj.id)?(
                        <div className='admin__tabel-tabel-filters d-flex align-center flex-wrap'>
                            <div className='admin__tabel-tabel-filters-item city'>
                                {adminTabelFilters.city.name}
                            </div>

                            <div className='admin__tabel-tabel-filters-point'/>

                            <div className='admin__tabel-tabel-filters-item school'>
                                {adminTabelFilters.school.name}
                            </div>

                            <div className='admin__tabel-tabel-filters-point'/>
                            
                            <div className='admin__tabel-tabel-filters-item class-name'>
                                {adminTabelFilters.classObj.number}{(adminTabelFilters.classObj.letter !== 'группа')? `${adminTabelFilters.classObj.letter} класс`: ` ${adminTabelFilters.classObj.letter}`}
                            </div>

                            <div className='admin__tabel-tabel-filters-point'/>

                            <div className='admin__tabel-tabel-filters-item program d-flex align-center'>
                                <div>{adminTabelFilters.program.name}</div>
                                {
                                    (adminTabelFilters.program.teacherName)? <div className='admin__tabel-tabel-filters-item-program-icon' data-tooltip={adminTabelFilters.program.teacherName}/>: null
                                }
                            </div>

                            {
                                (adminTabelFilters.subgroup.id)?<>
                                    <div className='admin__tabel-tabel-filters-point'/>

                                    <div className='admin__tabel-tabel-filters-item subgroup'>
                                        {adminTabelFilters.subgroup.name}
                                    </div>
                                </>:null
                            }

                            <div className='admin__tabel-tabel-filters-point'/>

                            <div className='admin__tabel-tabel-filters-item month-year'>
                                {adminTabelFilters.month.label} {adminTabelFilters.year}
                            </div>
                        </div>
                    ): <div className='admin__tabel-tabel-filters admin__tabel-tabel-filters-item'>Фильтры не выбраны</div>
                }

                {
                    tabelData.length? <TabelBlock pagesIsActive={pages.pages > 1}  isClosed={adminTabelIsClosed} tabelTeacherPassDates={tabelTeacherPassDates} tabelIsEdit={tabelIsEdit} setPopupType={setPopupType} tabelData={tabelData} newDate={newDate} getLessonsPageFunc={(data)=>getPageElems({data, page: page, elemsCount: pages.elems})} dateActive={{date: calendPopupPosition.date, id: calendPopupPosition.dateId}} editLessonFunc={editLessonFunc} dateClickFunc={popupOpenCalendFunc} createNewDateFunc={createNewDateFunc}/> :<DefaultTabel/>
                }

                {
                    (pages.pages > 1)? <PagesBlock activePage={page} pages={getPagesMass(pages.pages)} setPage={setPage}/>: null
                }                
            </div>

            <TabelCalendar 
                checkDate={calendPopupPosition.calendDate? calendPopupPosition.calendDate: null}
                checkDateId={calendPopupPosition.dateId}
                error={calendError} 
                setCheckDate={setCalendPopupPositionFunc} 
                deleteDateFunc={()=>{
                    setCalendDeleteDate({date: calendPopupPosition.date, id: calendPopupPosition.dateId})
                    setEditingPassDate({date: '', isTeacherPass: false, comment: ''}) 
                    setCalendActive(false)
                    setCalendPopupPosition({
                        ...calendPopupPosition,       
                        calendDate: '',
                        date: '',
                        dateId: 0
                    })
                    setPopupType('Delete')                    
                }} 
                passDate={editingPassDate} 
                setPassDate={setEditingPassDate} 
                classes={`calendar-not-neighboring-month-click calendar-not-month-navigation ${calendActive? 'active': 'deactive'}`} 
                style={{top: calendPopupPosition.top, left: calendPopupPosition.left}}
            />
            {/* }} passDate={editingPassDate} setPassDate={setEditingPassDate} classes={`calendar-not-neighboring-month-click calendar-not-month-navigation ${calendActive? 'active': 'deactive'}`} style={{top: calendPopupPosition.top, left: calendPopupPosition.left}}/> */}
            <AdminPopup type={popupType} props={{typeEdit: setPopupType}}/>
            <ConfirmCancel popupBack={(popupType === 'Confirm')? 'open': ''}  props={{title: 'Подтверждение', subtitle:<>Вы действительно хотите подтвердить изменения табеля?</>, closePopup: setPopupType, clickFunk:(setLoading)=>saveCancelTabelFunc(setLoading, true)}}/>
            <ConfirmCancel popupBack={(popupType === 'Cancel')? 'open': ''} props={{title: 'Отмена', subtitle:<>Вы действительно хотите отменить изменения табеля?</>, closePopup: setPopupType, clickFunk:(setLoading)=>saveCancelTabelFunc(setLoading, false)}}/>
            <DeleteBlock popupBack={(popupType === 'Delete')? 'open': ''} props={{title: 'Удаление', subtitle:<>Вы действительно хотите удалить занятие?</>, closePopup: setPopupType, clickFunk:(setLoading)=>deleteDateFunc(calendDeleteDate, setLoading)}}/>
            <WarningBlock popupBack={(popupType === 'Warning')? 'open': ''} props={{title:'Ошибка', subtitle: error, closePopup: setPopupType}}/>
        </div>
    )
}

export default Tabel;