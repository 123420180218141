import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosProxyCatchGet } from '../../../../axiosProxy';
import axiosProxy from './../../../../axiosProxy';


export const fetchAdminFinanceFullDocumentsData = createAsyncThunk(
  'AdminFinanceDocuments/fetchAdminFinanceFullDocumentsData',
  async (dataObj={clientId: 0}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-four-document?`+ new URLSearchParams(dataObj))
      if(response.status < 400){
        return {documents: response.data.data};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);

export const fetchAdminFinanceDocumentsData = createAsyncThunk(
  'AdminFinanceDocuments/fetchAdminFinanceDocumentsData',
  async (dataObj={studentId: 0, page: 0, count: 50, type: 1, type: 2}) => {
    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-document-low?`+ new URLSearchParams(dataObj))
      if(response.status < 400){
        return {documents: response.data.data, type: dataObj.type};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


export const fetchAdminFinanceDocumentsTemplateData = createAsyncThunk(
  'AdminFinanceDocuments/fetchAdminFinanceDocumentsTemplateData',
  async () => {
    try {
      const response = await axiosProxy.get(`/fetchAdminFinanceDocumentsTemplateData`)
      if(response.status < 400){
        return {documents: response.data.data};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');        
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


export const fetchAdminFinanceCertificateData = createAsyncThunk(
  'AdminFinanceDocuments/fetchAdminFinanceCertificate',
  async (dataObj={studentId: 0, page: 0, count: 30, type: 3, isScrolling: false}) => {

    try {
      const response = await axiosProxy.get(`/api/v1/administrative/finance/get-document-low?`+ new URLSearchParams(dataObj))
      if(response.status < 400){
        return {certificate: response.data.data, isScrolling: dataObj.isScrolling, page: dataObj.page};
      }
      else{
        if(response && response.response && response.statusText) console.log(response.response.statusText)
        else console.log('Что-то пошло не так.');          
      }      
    } catch (error) {
        // console.log(error);
    }
  },
);


