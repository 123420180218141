import { createSlice } from '@reduxjs/toolkit';
import { fetchUserAegrotatsData, fetchUserDocumentsData } from './asyncActions';
import { additionalDocumentsData, documentsData } from '../../../defaultData/user/documents/documents';

const initialState = {
  documentsData: [
    // ...documentsData
  ],

  additionallyDocumentsData: [
    // ...additionalDocumentsData
  ],

  aegrotatsData: [
    
  ],

  loading: false,
  pageLast: false,
  scrollReset: false,

  selectionDocument: '',
  
  blockedDocuments: ['4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b'],

};

const userDocuments = createSlice({
  name: 'UserDocuments',
  initialState,
  reducers: {
    setUserDocuments(state, action) {
      state.documentsData = action.payload;    
    },
    setUserDocumentsElemEdit(state, action) {
      // console.log(action.payload);
      state.documentsData = state.documentsData.map(item=>(item.id === action.payload.id || item.layoutId === action.payload.id)? {...item, ...action.payload, id: item.id}: item); 
    },


    setUserAdditionallyDocuments(state, action) {
      state.additionallyDocumentsData = action.payload;    
    },
    setUserAdditionallyDocumentsElemEdit(state, action) {
      state.additionallyDocumentsData = state.additionallyDocumentsData.map(item=>(item.id === action.payload.id || action.payload.id === item.layoutId)? {...item, ...action.payload}: item); 
    },


    setSelectionDocument(state, action) {
      state.selectionDocument = action.payload;    
    },


    setUserAegrotats(state, action) {
      state.aegrotatsData = action.payload;    
    },
    setUserAegrotatsAdd(state, action) {
      state.aegrotatsData = [...state.aegrotatsData, action.payload]
    },

    setUserAegrotatsScrollReset(state, action) {
      state.scrollReset = action.payload;      
    },

    setBlockedDocumentsAdd(state, action){
      state.blockedDocuments = [...state.blockedDocuments, action.payload];
    },

    setBlockedDocumentsDelete(state, action){
      state.blockedDocuments = state.blockedDocuments.filter(item => item !== action.payload);
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserDocumentsData.pending, (state, action) => {
      state.documentsData = [];
      state.additionallyDocumentsData = [];
    });
    builder.addCase(fetchUserDocumentsData.fulfilled, (state, action) => {
        if(action.payload && Array.isArray(action.payload.data)){
          state.documentsData = action.payload.data.filter(item => item.type === 1);
          state.additionallyDocumentsData = action.payload.data.filter(item => item.type === 2);
        }
        else{
          state.documentsData = [];
          state.additionallyDocumentsData = [];
        }
    });
    builder.addCase(fetchUserDocumentsData.rejected, (state, action) => {
    });

    builder.addCase(fetchUserAegrotatsData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUserAegrotatsData.fulfilled, (state, action) => {

      if(action.payload && action.payload.data && Array.isArray(action.payload.data)){
        if(action.payload.isScrolling){
          state.aegrotatsData = [...state.aegrotatsData, ...action.payload.data];
        }
        else
          state.aegrotatsData = action.payload.data;

        if(action.payload.page === 0) state.scrollReset = true;

        if(action.payload.data.length < 10) state.pageLast = true;
        else state.pageLast = false;
        
        state.loading = false;
      }
    });
    builder.addCase(fetchUserAegrotatsData.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

const { actions, reducer } = userDocuments;


export default reducer;

export const { 
  setUserDocuments,
  setUserDocumentsElemEdit,

  setUserAdditionallyDocuments,
  setUserAdditionallyDocumentsElemEdit,

  setUserAegrotats,
  setUserAegrotatsAdd,
  setUserAegrotatsScrollReset,

  setSelectionDocument,

  setBlockedDocumentsAdd,
  setBlockedDocumentsDelete

} = actions;

