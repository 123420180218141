import { getDateFormatFunc } from "../../../../../services/clientHome";
import TabelLine from "../TabelLine";

const TabelDateElem = ({id, date, dateClickFunc, dateActive, newDate, tabelTeacherPassDates, isClosed, dateHaveDublicate=false}) =>{
    let dateIsBlocking = tabelTeacherPassDates.filter(item=> item.date === date && item.isTeacherPass && item.id === id);
    dateIsBlocking = (dateIsBlocking.length)? dateIsBlocking[0]: null;
    return(
        <div className={`admin__tabel-table-line-item date admin__tabel-table-line-item-date ${(isClosed)? 'tabel-date-not-edited': ''} ${(dateIsBlocking)? 'tabel-date-blocked': ''}`} onClick={(e) => {
            dateClickFunc(e.currentTarget, date, id)                
        }}>            
            <div className={`admin__tabel-table-line-item-container admin__tabel-table-line-item-date d-flex align-center justify-center ${((dateActive.date === date && dateActive.id === id) || (newDate.date === date && newDate.id === id))? 'active': ''}`}>
                <div>{date.slice(0, 5)} <span>{(id && dateHaveDublicate)? `(${id})`: ''}</span></div>
                {
                    (dateIsBlocking && dateIsBlocking.comment)? <div className={`admin__tabel-table-line-item-hover-title`} data-tooltip={dateIsBlocking.comment}/>: null
                }                
            </div>
        </div>
    )
}

const TabelBlock = ({tabelData, dateClickFunc, editLessonFunc, createNewDateFunc, getLessonsPageFunc, newDate, tabelIsEdit, setPopupType,  tabelTeacherPassDates, dateActive='', isClosed=false, pagesIsActive, classes='', isConfirm=null}) =>{    
    return(
        <div className={`admin__tabel-table ${pagesIsActive? 'pages-active': ''} ${classes}`}>
            <div className='admin__tabel-table-line title d-flex justify-between align-center'>
                <div className="admin__tabel-table-line-item name">
                    <div className='admin__tabel-table-line-item-container name'>Ученики</div>
                </div>
                {
                    (tabelData[0].lessons && tabelData[0].lessons.length)? getLessonsPageFunc(tabelData[0].lessons).map(
                        item => <TabelDateElem 
                            id={item.id} 
                            dateHaveDublicate={tabelData[0].lessons.filter(lesson=>(item.date === lesson.date)).length > 1} 
                            date={item.date} 
                            newDate={newDate} 
                            dateClickFunc={dateClickFunc} 
                            isClosed={isClosed} 
                            dateActive={dateActive} 
                            tabelTeacherPassDates={tabelTeacherPassDates} 
                            key={`admin__tabel-table-title-line-item-date-${item.date}${item.id? `-${item.id}`: ''}`}
                        />
                    ): null
                }
                <button className={`admin__tabel-table-line-title-button d-flex justify-center align-center ${isClosed? 'deactive-button': ''}`} onClick={createNewDateFunc}>
                    <div className='admin__tabel-table-line-title-button-icon'/>
                </button>
            </div>
            
            <div className='admin__tabel-table-container'>
                {
                    tabelData.map(item=> <TabelLine item={item} key={`admin__tabel-table-line-id-${item.id}`} getLessonsPageFunc={getLessonsPageFunc} isClosed={isClosed} editLessonFunc={editLessonFunc} tabelTeacherPassDates={tabelTeacherPassDates}/>)
                }            
            </div>

            {
                (isConfirm && !tabelIsEdit)?
                <div className="admin__tabel-table-buttons d-flex justify-end">
                    <button className={`admin__tabel-table-buttons-item`} onClick={isConfirm.closePopup}>
                        Назад
                    </button>
                    <button className={`admin__tabel-table-buttons-item save`} onClick={isConfirm.closedTabelFunc}>
                        Подтвердить
                    </button>
                </div>
                :<div className="admin__tabel-table-buttons d-flex justify-end">
                    <button className={`admin__tabel-table-buttons-item ${tabelIsEdit? '': 'deactive-button'}`} onClick={()=>setPopupType('Cancel')}>
                        Отменить изменения
                    </button>
                    <button className={`admin__tabel-table-buttons-item save ${tabelIsEdit? '': 'deactive-button'}`} onClick={()=>setPopupType('Confirm')}>
                        Сохранить изменения
                    </button>
                </div>
                
            }
        </div>
    )
}

export default TabelBlock;