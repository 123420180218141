import Child from './Child';
import './index.scss'
import Program from './Program/index';
import PaymentHistory from './PaymentHistory/index';
import { useDispatch, useSelector } from 'react-redux';
import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';
import { useState, useEffect } from 'react';
import UserPopup from './../Popup/UserPopup';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { selectUserPrograms } from './../../../redux/users/programs/selectors';
import { setProgramChecked, setUserProgramsConectedChild, setUserProgramsDisableChild } from '../../../redux/users/programs/programsSlice';
import { setUserCheckChild, setUserFinanceChildrenDataProgramsConected, setUserFinanceChildrenDataProgramsDisable } from '../../../redux/users/children/childrenSlice';
import { fetchUserFinanceChildsData } from '../../../redux/users/children/asyncActions';
import { fetchUserProgramsData } from '../../../redux/users/programs/asyncActions';
import { axiosProxyCatchPost } from '../../../axiosProxy';
import { fetchUserProfileData } from './../../../redux/users/profile/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';
import WarningBlock from '../../Teacher/Popup/WarningBlock/WarningBlock';



const Finance = () =>{
    const dispatch = useDispatch();

    const { financeChildrenData } = useSelector(selectUserChildren)
    const { programsData, programChecked } = useSelector(selectUserPrograms)

    const [conectedLoadingChilds, setConectedLoadingChilds] = useState([])
    

    const [warningBlockData, setWarningBlockData] = useState({title: '', subTitle: ''})
    const [popupType, setPopupType] = useState('')
    const [conectedProgramActive, setConectedProgramActive] = useState(false)

    const paymentFunc = (elem) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('')
        setPopupType('Payment')
        dispatch(setUserCheckChild(elem))
    }

    const conectedProgramActivateFunc = (type, item) =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('')
        setConectedProgramActive(type)
        dispatch(setProgramChecked(item))
    }

    
    const childProgramConectedAndDisabled = (type, child, program) =>{
        setConectedLoadingChilds([...conectedLoadingChilds, child.id])
        axiosProxyCatchPost({
            url: type === 'conected'? '/api/v1/parental/finance/Create-additional': '/api/v1/parental/finance/shutdown-additional', 
            
            data:{studentId: child.id, programId: program.id},
            resolved: () =>{
                if(type === 'conected'){
                    dispatch(setUserProgramsConectedChild({programId: program.id, childId: child.id}))
                }
                else if(type === 'disabled'){
                    dispatch(setUserProgramsDisableChild({programId: program.id, childId: child.id}))
                }
                if((type === 'conected' && program.permittedChildren.length === program.conectedChildren.length + program.awaitsConectedChildren.length + 1)||(type === 'disabled' && program.conectedChildren.length - 1 === 0)){
                // if((type === 'conected' && program.conectedChildren.length + program.awaitsDisabledChildren.length + program.awaitsConectedChildren.length + 1 === financeChildrenData.length)||(type === 'disabled' && program.conectedChildren.length + program.awaitsDisabledChildren.length + program.awaitsConectedChildren.length - 1 === 0)){
                    dispatch(setUserPopupBack(''))
                    setPopupType('')
                    setConectedProgramActive(false)
                }

                setConectedLoadingChilds(conectedLoadingChilds.filter(item=> item.id !== child.id))

                setWarningBlockData({active: true, ...((type === 'conected')? {title: 'Подключение программы', subTitle: 'Ваша заявка на подключение программы отправлена.'}: {title: 'Отключение программы', subTitle: 'Ваша заявка на отключение программы отправлена.'})})

            },
            rejected: (error) =>{
                console.log(error);
                setConectedLoadingChilds(conectedLoadingChilds.filter(item=> item.id !== child.id))
            }
        })
    }

    useEffect(()=>{
        dispatch(fetchUserFinanceChildsData())
        dispatch(fetchUserProfileData())
        dispatch(setUserNavBarActive(2))
        
        dispatch(fetchUserProgramsData())
    }, [])

    

    return (
        <div className="user-page__finance">
            <div className='user-page__title'>Дети</div>
            <div className='user-page__finance-children d-flex flex-wrap'>
                {
                    financeChildrenData.map((item)=> <Child key={`user-page__finance-child-component-${item.id}`} programChecked={programChecked} item={item} clickFunc={paymentFunc} conectedProgramActive={conectedProgramActive} conectedLoadingChilds={conectedLoadingChilds} conectedButtonFunc={conectedProgramActivateFunc} childProgramConectedAndDisabled={childProgramConectedAndDisabled}/>)
                }
            </div>

            <div className={`user-page__title mt-30 ${programsData.length? '': 'd-none'}`}>Дополнительные программы</div>
            {/* <div className={`user-page__title mt-30`}>Дополнительные программы</div> */}
            <div className='user-page__finance-programs d-flex flex-wrap'>
                {
                    programsData.map((item)=><Program key={`user-page__finance-program-component-${item.id}`} programChecked={programChecked} item={item} childrenLength={financeChildrenData.length} conectedButtonFunc={conectedProgramActivateFunc} conectedProgramActive={conectedProgramActive}/>)
                }
            </div>

            <div className='user-page__title mt-30'>История платежей</div>
            <PaymentHistory/>
            <UserPopup type={popupType} props={{typeEdit: setPopupType, setConectedProgramActive: setConectedProgramActive}} />
            <WarningBlock popupBack={warningBlockData.active? 'open': ''} classes={conectedProgramActive? 'z-index-9': ''} props={{title:warningBlockData.title, subtitle: warningBlockData.subTitle, closePopup: ()=>{
                setWarningBlockData({...warningBlockData, active: false})
                setTimeout(()=>{
                    setWarningBlockData({active: false, title: '', subtitle: ''})
                }, 1000)
            }}}/>
        </div>
    )
}

export default Finance;