import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectAdminFinanceChilds } from '../../../../../redux/admin/finance/childs/selectors';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { convertNumber, getDateFormatFunc, getDateFormatFuncServer } from '../../../../../services/clientHome';
import { setAdminFinanceChildsDataEdit } from '../../../../../redux/admin/finance/childs/childsSlice';
import { isCorrectDate } from './../../../../../services/clientHome';
import MaskedInput from './../../../../MaskedInput/MaskedInput';


const FinanceMatcapPayment = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()

    const { adminFinanceCheckedChild:checkChild } = useSelector(selectAdminFinanceChilds);

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        summ: 0,
        date: new Date().toLocaleDateString(),
        lastPayment: false,
    })

    useEffect(()=>{
        if(checkChild.id){
            setFormData({
                summ: checkChild.yearPrice? checkChild.yearPrice:'',
                date: new Date().toLocaleDateString(),
                lastPayment: false,
            })
        }
        else{
            setFormData({
                summ: '',
                date: new Date().toLocaleDateString(),
                lastPayment: false,
            })
        }
    }, [popupBack])

    const createPaymentPostFunk = () =>{
        setError('')
        let {summ, date, lastPayment} = formData;
        
        let postData = {
            studentId: checkChild.id,
            summ: +summ,
            date: getDateFormatFuncServer(date),
        }
        if(lastPayment) postData.isFinish = lastPayment
        
        let fieldVal = {}
        if(!(date.length === 10 && date.indexOf('_') === -1 && isCorrectDate(date)))
            fieldVal.date = true;

        if(summ && !Object.keys(fieldVal).length){
            setLoading(true)
            axiosProxyCatchPost({
                url: '/api/v1/administrative/finance/mat-cap-payment',
                data: postData,
                resolved: (data)=>{
                    dispatch(setAdminFinanceChildsDataEdit({...formData, id: checkChild.id, status: data.status, yearPrice: checkChild.yearPrice - postData.summ, matcap: !formData.lastPayment}))
                    setLoading(false)
                    closePopup()
                },
                rejected: (error)=>{
                    setLoading(false)
                    if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                    else setError('Что-то пошло не так*')                      
                }
            })
        }
        else if(Object.keys(fieldVal).length){
            setError('Некорректная дата*')
        }
        else setError('Необходимо заполнить все поля*')
    }

    return(
        <div className={`popup__container admin-popup__container program-edit`}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Мат.Капитал
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label htmlFor="" className='popup__label mb-4'>Сумма</label>
            <div className='user-popup__payment-input-block mb-16'>
                <input className='user-popup__payment-input-block-input' type="number" value={formData.summ} onChange={(e)=>setFormData({...formData, summ: e.target.value})} />
                {/* <input className='user-popup__payment-input-block-input' type="number" value={formData.summ} onChange={(e)=>setFormData({...formData, summ: e.target.value})} /> */}
                <div className='user-popup__payment-input-block-val'>{formData.summ? convertNumber(formData.summ): ''}{formData.summ? '₽': ''}</div>
            </div>

            <label className="popup__label mb-4">Дата платежа</label>
            <MaskedInput
                className={`popup__input mb-16`}
                mask="__.__.____"
                replacement={{_ : /[0-9]/}}
                showMask={true}
                separate={true}
                defaultValue={formData.date}
                name = 'endDate'
                // readOnly={false}
                readOnly={false}
                placeholder=''
                onChange={(e)=>{
                    setFormData({...formData, date: e.target.value})
                }}
            />
            <div className={`popup__checkbox ${formData.lastPayment? 'active': ''}`} onClick={()=>{
                setFormData({...formData, lastPayment: !formData.lastPayment})
            }}>
                Последний платеж
            </div>


            <div className='popup__error'>{error}</div>     

            <div className='d-flex'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>closePopup('')}>Отменить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={createPaymentPostFunk}>Сохранить</button>
            </div>       
        </div>
    )
}

export default FinanceMatcapPayment;