import './index.scss'
import Calend from './../../../../Calendar/Calendar';


const TabelCalendar = ({checkDate, setCheckDate, classes, style, error, passDate, setPassDate, deleteDateFunc}) =>{
    return(
        <div className={`admin__tabel-calendar-container ${classes}`} style={style}>
            <Calend checkDate={checkDate} setCheckDate={setCheckDate} classes={classes} style={style}/>
            <div className='admin__tabel-calendar-container-under-line'/>
            <div className='popup__error'>{error}</div>
            <div className='popup__switch-container admin__tabel-calendar-container-switch-container d-flex align-center'>
                <div className={`popup__switch mr-5 ${passDate.isTeacherPass? 'active': ''}`} onClick={()=>setPassDate({...passDate, isTeacherPass: !passDate.isTeacherPass})}>
                    <div className={`popup__switch-btn`}/>
                </div>
                <div className='popup__switch-label'>Пропуск преподователя</div>
            </div>
            <input type="text" readOnly={!passDate.isTeacherPass} placeholder='Комментарий к пропуску...' className='admin__tabel-calendar-container-comment' value={passDate.comment} onChange={(e)=> setPassDate({...passDate, comment: e.target.value})}/>
            <button className='admin__tabel-calendar-container-button' onClick={()=>deleteDateFunc()}>
                Удалить занятие
            </button>
        </div>
    )
}

export default TabelCalendar;