import { getChildPaymentStatus } from "../../../../../../services/clientHome";
import ProgramElem from "../ProgramElem";
import { getMonthName, getStringAcademicYear } from './../../../../../../services/clientHome';


const ChildListElem = ({item, clickFunc, matcapOpen=()=>{}}) =>{
    const {id, name, status, matcap, city, school, classObj, programs, startMonth:month, endDate, isFinish} = item;
    let statusData = getChildPaymentStatus(status)

    return(
        <div className={`admin__finance-user-card-child-list-item`}>
            <div className='admin__finance-user-card-child-list-item-top d-flex align-center mb-16'>
                <div className='admin__finance-user-card-child-list-item-child-data-icon' onClick={clickFunc}/>
                <div className='admin__finance-user-card-child-list-item-child-data'>
                    <div className='admin__finance-user-card-child-list-item-child-data-name mb-8 d-flex align-center justify-between'>
                        <div className={`admin__finance-user-card-child-list-item-child-data-name-label ${(endDate && isFinish)? 'graduated-active': ''}`}>
                            {/* {name} <span>({getMonthName(month)} - {(endDate && isFinish)? getMonthName(new Date(endDate).getMonth()+1) :'Май'})</span> */}
                            {name} <span>({getMonthName(month)} - {(endDate && isFinish)? getMonthName(new Date(endDate).getMonth() === 0? 12: new Date(endDate).getMonth()) :'Май'})</span>
                        </div>
                        {
                            (endDate && isFinish)
                            ?<div className="admin__finance-user-card-child-list-item-child-data-name-status-container graduated">
                                {
                                    getStringAcademicYear(new Date(endDate))
                                }
                            </div>
                            :<div className={`admin__finance-user-card-child-list-item-child-data-name-status-container d-flex justify-end align-center`}>
                                {/* {
                                    ((matcap))?
                                    <div className={`admin__finance-user-card-child-list-item-child-data-name-status-mat-cap`} onClick={()=>{
                                        matcapOpen(item)  
                                    }}>Внести платеж</div>
                                    :null
                                } */}
                                <div className={`admin__finance-user-card-child-list-item-child-data-name-status ${statusData.color}`}>{(matcap)? 'Мат.Капитал': statusData.label}</div>

                            </div>
                        }

                    </div>
                    <div className='admin__finance-user-card-child-list-item-child-data-address-school-class d-flex align-center'>
                        <div>{city.name}</div> <span/> <div>{school.name}</div> <span/> <div>{classObj.number}{classObj.letter}</div>
                    </div>
                </div>
                
            </div>
            <div className="admin__finance-user-card-child-list-item-program-list">
                {
                    programs.map(item => <ProgramElem item={item} key={`admin__finance-user-card-child-list-item-program-list-container-${item.id}`}/>)
                }
            </div>
            <div className="d-flex justify-end align-center mt-16">
                {
                    ((matcap))?
                    <div className={`admin__finance-user-card-child-list-item-child-data-name-status-mat-cap`} onClick={()=>{
                        matcapOpen(item)  
                    }}>Внести платеж</div>
                    :null
                }
            </div>

        </div>
    )
}

export default ChildListElem;