export let financeUserChilds = [
    {
        "id": "01000000-ac12-0242-885c-08dcd306f531",
        "name": "Алиева Фатима Омаровна",
        "status": 0,
        endDate: "2024-06-26T12:00:00+00:00",
        isFinish: true,
        "city": {
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b",
            "name": "Махачкала"
        },
        "school": {
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43",
            "name": "МБОУ \"Лицей №5\" г. Махачкала"
        },
        "classObj": {
            "id": "01000000-ac12-0242-22b9-08dcd1adce16",
            "number": 1,
            "letter": "А"
        },
        "programs": [
            {
                "id": "01000000-ac12-0242-b170-08dcd1ae0242",
                "name": "Занимательная математика, ступень 1",
                "color": "green",
                "date": "09/13/2024",
                "summ": 9441
            },
            {
                "id": "01000000-ac12-0242-d3b5-08dcd1ae0264",
                "name": "Проектная деятельность (введение в науку), ступень 1",
                "color": "light_blue",
                "date": "09/13/2024",
                "summ": 9450
            },
            {
                "id": "01000000-ac12-0242-f36a-08dcd1ae0288",
                "name": "Логика, ступень 1",
                "color": "yellow",
                "date": "09/13/2024",
                "summ": 9450
            }
        ],
        "matcap": false,
        "birthDay": "17.09.2017",
        startMonth: 9,
        startDate: '12.12.2024',
        isFullVerify: false,
        isHaveAdditionals: false,
    },
    {
        "id": "01000000-ac12-0242-9778-08dcd306f52b",
        "name": "Алиева Айшат Омаровна",
        "status": 0,
        endDate: "2024-06-26T12:00:00+00:00",
        isFinish: false,
        "city": {
            "id": "01000000-ac12-0242-ffd2-08dcd1ad717b",
            "name": "Махачкала"
        },
        "school": {
            "id": "01000000-ac12-0242-5149-08dcd1ad8b43",
            "name": "МБОУ \"Лицей №5\" г. Махачкала"
        },
        "classObj": {
            "id": "01000000-ac12-0242-178d-08dcd1adcecf",
            "number": 2,
            "letter": "-8"
        },
        "programs": [
            {
                "id": "01000000-ac12-0242-933b-08dcd1ae02a9",
                "name": "Занимательная математика, ступень 2",
                "color": "yellow",
                "date": "09/13/2024",
                "summ": 9441
            },
            {
                "id": "01000000-ac12-0242-7ff4-08dcd1ae02ca",
                "name": "Проектная деятельность (введение в науку), ступень 2",
                "color": "light_blue",
                "date": "09/13/2024",
                "summ": 9450
            },
            {
                "id": "01000000-ac12-0242-15f2-08dcd1ae02ec",
                "name": "Логика, ступень 2",
                "color": "orange",
                "date": "09/13/2024",
                "summ": 9450
            }
        ],
        "matcap": true,
        matcapIsClosed: true,
        "birthDay": "01.08.2016",
        startMonth: 11,
        startDate: '12.12.2024',
        yearSumm: 36800,
        isFullVerify: true,
        isHaveAdditionals: true,
    }
]