import { downloadFilePost, getMonthName, UploadFileFunc } from '../../../../../../services/clientHome';
import { PaginationMonth, TerminationMonth } from '../../../../../Filters/FiltersList';
import { getStatus } from '../../../../../User/Documents/DocumentElem';
import { useEffect, useState } from 'react';


const downloadFileFunc = (e, id, layoutId, sendFunc, docTypeId, setLoading) =>{    
    UploadFileFunc(e,
        (uploadFileData)=>{
            if(uploadFileData){
                let resultFile = {
                    id: id,
                    layoutId: layoutId,
                    extension: uploadFileData.extension,
                    status: 1,
                    link: uploadFileData.link,
                    fileBytes: uploadFileData.fileBytes,
                };
                sendFunc(resultFile, docTypeId, setLoading)
            }  
        }
    )          
}

const FinanceTerminationDocumentElem = ({item, childId, docTypeId, confirmLoading, activeList, accessEditFunc, endDate=null, setActiveList=()=>{}, sendFunc=()=>{}, deleteFunc=()=>{}, confirmFunc=()=>{}, refuseFunc=()=>{}}) =>{
    const {id, layout, appId, documentId, name, date, status, extension='', comment='', link} = item;
    const statusObj = getStatus(status)
    const [loading, setLoading] = useState(false)
    const [additionalIsActive, setAdditionalIsActive] = useState(false)
    const [additionalOverflow, setAdditionalOverflow] = useState(false)
    const [formData, setFormData] = useState({
        month: endDate? 
        {
            id: new Date(endDate).getMonth() + 1,
            label: getMonthName(new Date(endDate).getMonth() + 1),
            date: new Date(endDate)
        }
        :{
            id: 0,
            label:'',
            date: ''
        }
    })

    useEffect(()=>{
        setAdditionalIsActive(false)
        setAdditionalOverflow(false)
    }, [childId])

    useEffect(()=>{
        setFormData({
            month: endDate? 
            {
                id: new Date(endDate).getMonth() + 1,
                label: getMonthName(new Date(endDate).getMonth() + 1),
                date: new Date(endDate)
            }
            :{
                id: 0,
                label:'',
                date: ''
            }
        })
    }, [endDate, childId])

    return(
        <div className={`admin-finance-popup__documents-line dsfsfsdfsdf ${(status === -1)? 'confirm-line': ''}`}>
            <div className='d-flex align-center justify-between'>
                <div className='admin-finance-popup__documents-line-item name d-flex align-center'>
                    <div className='admin-finance-popup__documents-line-item-document-icon'/>
                    <div className='admin-finance-popup__documents-line-item-name'>{name}</div>
                </div>
                <div className='admin-finance-popup__documents-line-item date'>{date}</div>
                <div className={`admin-finance-popup__documents-line-item status ${(!endDate)? '': statusObj.color}`}>
                    <div className='admin-finance-popup__documents-line-item-status-label'>{!endDate? 'Документ скрыт': statusObj.label}</div>
                    {
                        (status === -1 && comment)? <div className='admin-finance-popup__documents-line-item-status-title' data-tooltip={comment}/>: null
                    }                    
                </div>
                <div className='admin-finance-popup__documents-line-item buttons d-flex justify-end align-center'>
                    {
                        (status === 0)?(
                            <>
                                <div className={`admin-finance-popup__documents-line-item-button confirm ${(loading || confirmLoading === id || confirmLoading === layout)? 'deactive-button': ''}`} onClick={()=>confirmFunc(id, layout, appId, status, 'termination')}/>
                                <div className={`admin-finance-popup__documents-line-item-button refuse admin-finance-popup__documents-line-item-button-refuse ${loading || confirmLoading === id || confirmLoading === layout? 'deactive-button': ''}`} onClick={(e)=>refuseFunc(e, id, appId, status)}/>
                                <div className={`admin-finance-popup__documents-line-item-button-line`}/>
                            </>
                        ): null
                    }

                    <div className={`admin-finance-popup__documents-line-item-button download ${(loading || confirmLoading === id || confirmLoading === layout || !endDate)? 'deactive-button': ''}`} onClick={() => downloadFilePost({url:(link && status !== -1)? '/api/v1/administrative/finance/get-file?': '/api/v1/administrative/finance/get-layout?', name, type: (extension && extension.indexOf('.') === -1)? '.'+extension: extension, postData: (link && status !== -1)? {documentId: documentId? documentId: id}: {layoutId: (status === -1)? layout :id, studentId: childId}, setLoading: setLoading})}/>

                    {/* <div className={`admin-finance-popup__documents-line-item-button send ${(status === 1 || status === 4)? '': 'deactive-button'}`} onClick={()=>sendFunc()}/> */}
                    
                    <input type="file" name='files' accept="" className='user-page__documents-doc-line-button d-none' id={`finance-documents-doc-download-input-${id}`} onChange={(e)=>downloadFileFunc(e, id, (status === -1)? layout: id, sendFunc, docTypeId, setLoading)}/>
                    <label className={`admin-finance-popup__documents-line-item-button send ${(confirmLoading !== 'blocked' && (status === -1 || !status) && !(loading || confirmLoading === id || confirmLoading === layout) && endDate)? '': 'deactive-button'}`} htmlFor={`finance-documents-doc-download-input-${id}`}/>

                    <div className='admin-finance-popup__documents-line-item-button-line'/>
                    <div className={`admin-finance-popup__documents-line-item-button delete ${(status === 1 && !(loading || confirmLoading === id || confirmLoading === layout))? '': 'd-none'}`} onClick={()=>deleteFunc(id, docTypeId)}/>
                    <div className={`admin-finance-popup__documents-line-item-button arrow ${(status === 1 && !(loading || confirmLoading === id || confirmLoading === layout))? 'd-none': ''} ${additionalIsActive? 'active': ''}`} onClick={()=>{
                        setAdditionalIsActive(!additionalIsActive)
                        if(!additionalIsActive) setTimeout(()=>setAdditionalOverflow(true), 100)
                        else setTimeout(()=>setAdditionalOverflow(false), 100)
                    }}/>
                </div>
            </div>
            <div className={`admin-finance-popup__documents-line-additional d-flex justify-between align-center ${(additionalOverflow)? 'overflow-visible': ''} ${additionalIsActive? 'active': ''}`}>
                {/* <TerminationMonth 
                    activeList={activeList} 
                    setActiveList={setActiveList} 
                    formData={formData} 
                    setFormData={setFormData}
                    classes={`admin-finance-popup__documents-line-termination-month ${endDate? 'not-click': ''}`}
                    isLastBlock={true}
                    // label={'Месяц'}
                    labelIsDisable={true}
                /> */}

                <PaginationMonth
                    activeList={activeList} 
                    setActiveList={setActiveList} 
                    formData={formData} 
                    setFormData={(formData)=>setFormData({...formData, month: {...formData.month, date: new Date(formData.month.year, formData.month.id - 1, 1)}})}
                    classes={`admin-finance-popup__documents-line-termination-month ${endDate? 'not-click': ''}`}
                    isLastBlock={true}
                    scrollClosed={true}
                    label={'Месяц'}                    
                    labelIsDisable={true}
                />
                <div className='admin-finance-popup__documents-line-termination-buttons d-flex justify-between align-center'>
                    <button className={`admin-finance-popup__documents-line-termination-button`} onClick={()=>{
                        setFormData({...formData, month: {id: 0, label:'', date: ''}})
                        setAdditionalIsActive(false)
                        setTimeout(()=>setAdditionalOverflow(false), 100)
                    }}>Закрыть</button>
                    <button className={`admin-finance-popup__documents-line-termination-button agree ${(formData.month && formData.month.date && status !== 1 && status !== 0)? '': 'deactive-button'}`} onClick={()=>accessEditFunc(formData.month.date, id, !endDate)}
                    
                    >{endDate? 'Скрыть документ': 'Открыть документ'}</button>
                </div>
            </div>
        </div>
    )
}

export default FinanceTerminationDocumentElem;