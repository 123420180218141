import DocumentIcon from '../../../../img/document-purple-icon.svg';
import DocumentRedIcon from '../../../../img/documents-red-icon.svg';
import DocumentGreenIcon from '../../../../img/document-green-icon.svg'

import '../DocumentElem/index.scss'
import { convertFileSize, downloadFilePost } from '../../../../services/clientHome';
import { useState } from 'react';

const getStatus = (id) =>{
    if(id === -1)
        return {color: 'red', label: 'Отказано'}
    else if(id === 0)
        return {color: 'red', label: 'Не подтвержден'}
    else if(id === 1)
        return {color: 'green', label: 'Подтвержден'}
}


const getDocumentIcon = (id) =>{
    if(id === -1 || id === 0) return DocumentRedIcon
    else if(id === 1) return DocumentGreenIcon
    else return DocumentIcon
}


const AegrotatElem = ({item, keyVal}) =>{
    const {id, name, size, docType, status, link, comment, date='',} = item;
    const [loading, setLoading] = useState(false)
    
    let statusObj = getStatus(status);

    return (
        <div className="user-page__documents-doc-line d-flex align-center" key={`${keyVal}-${id}`}>
            <div className='user-page__documents-doc-line-left d-flex align-center'>
                <img className="user-page__documents-doc-line-image mr-16" src={getDocumentIcon(status)} alt=""/>
                <div className='user-page__documents-doc-line-information'>
                    <div className='user-page__documents-doc-line-information-name mb-6'>{name}</div>
                    <div className='user-page__documents-doc-line-information-size-and-type d-flex align-center'>
                        <div>{convertFileSize(size)}</div> <span/> <div>{docType.replace('.', '')}</div> <span/> <div>{date}</div>
                    </div>
                </div>
            </div>
            <div className={`user-page__documents-doc-line-status ${statusObj.color}`}>
                <div className='user-page__documents-doc-line-status-label'>{statusObj.label}</div>
                {
                    (status === -1 && comment)? <div className='user-page__documents-doc-line-status-title' data-tooltip={comment}/>: null
                }                
            </div>
            <div className='user-page__documents-doc-line-buttons-container d-flex align-center'>
                <div className={`user-page__documents-doc-line-button d-flex justify-between align-center ${loading? 'deactive-button': ''}`} onClick={() => downloadFilePost({url: '/api/v1/parental/documents/get-my-file?', name: name, type: docType, postData: {documentId: id}, setLoading: setLoading})}>
                    <div className='user-page__documents-doc-line-button-label mr-8'>Скачать</div>
                    <div className={`user-page__documents-doc-line-button-icon download`}/>
                </div>
            </div>
        </div>
    )
}

export default AegrotatElem;