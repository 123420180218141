import './index.scss'
import { useEffect, useState } from 'react';
import DropDownList from '../../DropDownList';
import FinanceDocumentElem from './FinanceDocumentElem';
import FinanceCertificateElem from './FinanceCertificateElem';
import DocumentTemplates from './DocumentTemplates';
import RefusalComment from '../../RefusalComment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminFinanceChilds } from './../../../../../redux/admin/finance/childs/selectors';
import { selectAdminFinanceDocuments } from './../../../../../redux/admin/finance/documents/selectors';
import { fetchAdminFinanceDocumentsData, fetchAdminFinanceCertificateData, fetchAdminFinanceDocumentsTemplateData } from './../../../../../redux/admin/finance/documents/asyncActions';
import { getDateFormatFunc, scrollingPagination, getDateFormatFuncServer } from './../../../../../services/clientHome';
import { setAdminFinanceAdditionalyDocumentsData, setAdminFinanceAdditionalyDocumentsDataEdit, setAdminFinanceCertificateDataEdit, setAdminFinanceCertificateScrollReset, setAdminFinanceDocumentsDataEdit } from '../../../../../redux/admin/finance/documents/documentsSlice';
import { axiosProxyCatchPost } from '../../../../../axiosProxy';
import Calend from '../../../../Calendar/Calendar';
import FinanceTerminationDocumentElem from './FinanceTerminationDocumentElem';
import { setAdminFinanceChildsDataEdit } from '../../../../../redux/admin/finance/childs/childsSlice';


const documentsTypeData = [
    {
        id: 1,
        name: 'Основные'
    },
    {
        id: 2,
        name: 'Дополнительные'
    },
    {
        id: 3,
        name: 'Справки о болезни'
    },
]

const FinanceDocument = ({closePopup, popupBack}) =>{
    const dispatch = useDispatch()

    const { adminFinanceDocumentsData:documentsData, 
            adminFinanceAdditionalyDocumentsData:documentsAdditionalyData, 
            adminFinanceCertificateData:certificateData, 
            adminFinanceDocumentsTemplateData:documentTemplatesData, 
            adminFinanceCertificatePageLast:pageLast, 
            adminFinanceCertificateScrollReset:scrollReset, 
            adminFinanceCertificateLoading:loading 
     } = useSelector(selectAdminFinanceDocuments);

    const { adminFinanceChildsData:childData } = useSelector(selectAdminFinanceChilds);

    const [filtersData, setFiltersData] = useState({
        child:childData.length? childData[0]: {
            id: 0,
            name: '',
            isHaveAdditionals: false,
        },
        documentType: {
            id: 1,
            name: 'Основные'
        },
    })
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [commentIsActive, setCommentIsActive] = useState(0)
    const [commentIsActiveAppId, setCommentIsActiveAppId] = useState(0)
    const [checkedDocumentStatus, setCheckedDocumentStatus] = useState(0)
    const [page, setPage] = useState(0)
    const [openDropListName, setOpenDropListName] = useState('')
    const [refusePopupPosition, setRefusePopupPosition] = useState({
        top: 0,
        left: 0
    })
    const [calendPopupPosition, setCalendPopupPosition] = useState({
        top: 0,
        left: 0,
        id: 0,
        dateActive: '',
        startDate: '',
        endDate: ''

    })

    const [calendActive, setCalendActive] = useState(false)

    useEffect(()=>{
        if(popupBack){
            if(filtersData.documentType.id === 3){
                dispatch(fetchAdminFinanceCertificateData({studentId: filtersData.child.id, count: 30, page: 0, type: 3, isScrolling: false}))
                setPage(0)
            }
            else{
                dispatch(fetchAdminFinanceDocumentsData({studentId: filtersData.child.id, page: 0, count: 50, type: filtersData.documentType.id}))
            }
        }        
    }, [filtersData, popupBack])

    const popupOpenFunc = (e, id, appId='', documentStatus) => {
        if(!commentIsActive){
            const position = e.target.getBoundingClientRect()
            let x = position.left - 220;
            let y = position.top + 36;
            if(window.innerWidth < x + 220){
                x -= (window.innerWidth - (x + 220 + 26)) * -1;
            }
            if(window.innerHeight < y + 328){
                y -= (window.innerHeight - (y + 328 + 30)) * -1;
            }
            setCommentIsActive(id)
            setCheckedDocumentStatus(documentStatus)
            if(appId) setCommentIsActiveAppId(appId)
            setRefusePopupPosition({...refusePopupPosition, top: y, left: x})
        }
        else{
            setCommentIsActive(0)
            setCommentIsActiveAppId(0)
            setCheckedDocumentStatus(0)
        }
    }


    const popupOpenCalendFunc = (e, id, type, startDate, endDate) => {        
        if((calendPopupPosition.id === id && type !== calendPopupPosition.dateActive) || calendPopupPosition.id === 0 || !calendActive){
            const position = e.target.parentNode.getBoundingClientRect()
            let x = position.left - 220;
            let y = position.top + 36;
            if(window.innerWidth < x + 400){
                x -= (window.innerWidth - (x + 420 + 26)) * -1;
            }
            if(window.innerHeight < y + 440){
                y -= (window.innerHeight - (y + 440 + 30)) * -1;
                x += 400 
            }

            setCalendActive(((type === 'start' && calendPopupPosition.dateActive === 'start') || (type === 'end' && calendPopupPosition.dateActive === 'end'))?  false: true)            
            setCalendPopupPosition({
                id: id,
                dateActive: type === 'start'? (calendPopupPosition.dateActive === 'start'? '': 'start'): (calendPopupPosition.dateActive === 'end'? '': 'end'),
                startDate: id !== calendPopupPosition.id? startDate: calendPopupPosition.startDate,
                endDate: id !== calendPopupPosition.id? endDate: calendPopupPosition.endDate,
                top: y, 
                left: x
            })
        }
        else{
            setCalendActive(false)
            setCalendPopupPosition({
                ...calendPopupPosition,
                dateActive: ''
            })
        }
    }

    const sendDocumentFunc = (fileData, docTypeId, setLoading) =>{
        let postData = {
            file: fileData.fileBytes,
            extension: fileData.extension,
            layoutId: fileData.layoutId,
            studentId: filtersData.child.id,
        }
        setLoading(true)

        axiosProxyCatchPost({
            url:'/api/v1/administrative/finance/create-document', 
            data: postData,
            resolved: (response) =>{   
                let date = new Date().toLocaleDateString()             
                if(docTypeId === 1){
                    dispatch(setAdminFinanceDocumentsDataEdit({
                        id: fileData.id,
                        documentId: response.id,
                        status: 1,
                        date: date,
                        link: response.path,
                    }))    
                }                    
                else if(docTypeId === 2){
                    if(fileData.layoutId === '4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b'){
                        axiosProxyCatchPost({
                            url: '/api/v1/administrative/applications/accept-mat-cap',
                            data: {documentId: response.id},
                            resolved: (data)=>{
                                dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                                    id: fileData.id, 
                                    documentId: response.id,
                                    status: 1,
                                    date: date,
                                    link: response.path,
                                }))                                
                                dispatch(setAdminFinanceChildsDataEdit({id: filtersData.child.id, matcap: true}))
                            },
                            rejected: (error)=>{
                                                
                            }
                        })
                    }
                    else if(fileData.layoutId === "ed9e1e21-10e4-4c49-915e-87883632faab"){
                        axiosProxyCatchPost({
                            url: '/api/v1/administrative/applications/accept-finish-document',
                            data: {documentId: response.id},
                            resolved: (data)=>{
                                dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                                    id: fileData.id, 
                                    documentId: response.id,
                                    status: 1,
                                    date: date,
                                    link: response.path,
                                }))
                                dispatch(setAdminFinanceChildsDataEdit({id: filtersData.child.id, isFinish: true}))
                            },
                            rejected: (error)=>{
                                setConfirmLoading(0)                    
                            }
                        })
                    }
                    else{      
                        dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                            id: fileData.id, 
                            documentId: response.id,
                            status: 1,
                            date: date,
                            link: response.path,
                        }))
                    }
                }
                setLoading(false)
            },
            rejected: (error) =>{
                setLoading(false)
            },
        })
    }

    const deleteDocumentFunc = (id, docTypeId) =>{
        let postData = {
            id: id,
        }

        axiosProxyCatchPost({
            url:'/api/v1/administrative/finance/delete-document', 
            data: postData,
            resolved: (response) =>{   
                if(docTypeId === 1){                    
                    dispatch(setAdminFinanceDocumentsDataEdit({
                        id: id, 
                        status: null,
                        date: '',
                        link: '',
                    }))
    
                }                    
                else if(docTypeId === 2){                    
                    dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                        id: id, 
                        status: null,
                        date: '',
                        link: '',
                    }))
                }
            },
            rejected: (error) =>{

            },
        })
    }

    const refuseDocumentFunc = (comment='') =>{
        const postData = {
            id: commentIsActive,
            appId: commentIsActiveAppId,
            status: -1,
            documentStatus: checkedDocumentStatus
        }

        if(comment) postData.comment = comment;
        setConfirmLoading(commentIsActive)
        setCommentIsActive(0)

        DocumentConfirmPost(postData)
        
    }

    const confirmDocumentFunc = (id, layout='', appId='', documentStatus=0) =>{
        const postData = {
            id: id,
            status: 1,
            appId: appId,
            documentStatus: documentStatus
        }
        setConfirmLoading(id)
        
        if(layout === '4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b'){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/accept-mat-cap',
                data: {documentId: postData.id},
                resolved: (data)=>{
                    DocumentConfirmPost(postData)
                    dispatch(setAdminFinanceChildsDataEdit({id: filtersData.child.id, matcap: true}))
                },
                rejected: (error)=>{
                    setConfirmLoading(0)                    
                }
            })
        }
        else if(layout === "ed9e1e21-10e4-4c49-915e-87883632faab"){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/accept-finish-document',
                data: {documentId: postData.id},
                resolved: (data)=>{
                    dispatch(setAdminFinanceChildsDataEdit({id: filtersData.child.id, isFinish: true}))
                    DocumentConfirmPost(postData)
                },
                rejected: (error)=>{
                    setConfirmLoading(0)                    
                }
            })
        }
        else{
            DocumentConfirmPost(postData)
        }
        
    }
    
    const DocumentConfirmPost = (postData) => {
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-document-status',
            data: postData,
            resolved: (data)=>{
                if(filtersData.documentType.id !== 3 || postData.status === -1) updateApplicationStatusFunc(postData)

                if(filtersData.documentType.id === 1){                    
                    dispatch(setAdminFinanceDocumentsDataEdit({
                        id: postData.id, 
                        status: postData.status,
                        comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                    }))            
                }                    
                else if(filtersData.documentType.id === 2){                    
                    dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                        id: postData.id,
                        status: postData.status,
                        comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                    }))
                }
                else if(filtersData.documentType.id === 3 && postData.status === -1){                    
                    dispatch(setAdminFinanceCertificateDataEdit({
                        id: postData.id,
                        status: postData.status,
                        comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                    }))
                }
                else if(filtersData.documentType.id === 3 && postData.status === 1){
                    axiosProxyCatchPost({
                        url: '/api/v1/administrative/applications/accept-certificate',
                        data: postData,
                        resolved: (data)=>{
                            dispatch(setAdminFinanceCertificateDataEdit({
                                id: postData.id,
                                status: postData.status,
                                comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                            }))
                            updateApplicationStatusFunc(postData)
                        },
                        rejected: (error)=>{
                        }
                    })
                }
                setConfirmLoading(0)
            },
            rejected: (error)=>{
                setConfirmLoading(0)
            }
        })

        if(postData.documentStatus === null){
            updateApplicationStatusFunc(postData)
        }
    }

    const updateApplicationStatusFunc = (postData) =>{
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-application-status',
            data: {...postData, id: postData.appId},
            resolved: (data)=>{
                // if(filtersData.documentType.id === 1){                    
                //     dispatch(setAdminFinanceDocumentsDataEdit({
                //         id: postData.id, 
                //         status: postData.status,
                //         comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                //     }))            
                // }                    
                // else if(filtersData.documentType.id === 2){                    
                //     dispatch(setAdminFinanceAdditionalyDocumentsDataEdit({
                //         id: postData.id,
                //         status: postData.status,
                //         comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                //     }))
                // }
                // else if(filtersData.documentType.id === 3){                    
                //     dispatch(setAdminFinanceCertificateDataEdit({
                //         id: postData.id,
                //         status: postData.status,
                //         comment: (postData.status===-1 && postData.comment)? postData.comment: ''
                //     }))
                // }
                // setConfirmLoading(0)
            },
            rejected: (error)=>{
                // setConfirmLoading(0)                        
            }
        })
    }



    const refuseCertificateFunc = (comment) =>{
        const postData = {
            id: commentIsActive,            
            appId: commentIsActiveAppId,
            studentId: filtersData.child.id,
            status: -1,
            documentStatus: checkedDocumentStatus
        }
        if(comment) postData.comment = comment;
        setConfirmLoading(commentIsActive)
        setCommentIsActive(0)
        setCheckedDocumentStatus(0)

        DocumentConfirmPost(postData)
    }

    const confirmCertificateFunc = (id, appId, startDate, endDate, documentStatus) =>{
        const postData = {
            id: id,
            status: 1,
            appId: appId,
            documentStatus: documentStatus
        }
        setConfirmLoading(id)

        if(calendPopupPosition.id === id){
            if(calendPopupPosition.startDate) postData.startDate = getDateFormatFuncServer(calendPopupPosition.startDate);
            if(calendPopupPosition.endDate) postData.endDate = getDateFormatFuncServer(calendPopupPosition.endDate);
        }
           
        if(Object.keys(postData).length > 4){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/finance/update-certificate',
                data: postData,
                resolved: (data)=>{
                    dispatch(setAdminFinanceCertificateDataEdit({
                        id: id,
                        startDate: calendPopupPosition.startDate,
                        endDate: calendPopupPosition.endDate
                    }))
                    DocumentConfirmPost(postData)
                },
                rejected: (error)=>{                    
                    setConfirmLoading(0)
                }
            })
        }
        else{
            DocumentConfirmPost(postData)
        }
        
    }

    const terminationAccesEdit = (date, id, isOpen) =>{
        setConfirmLoading(id)
        let postData = {id: filtersData.child.id}
        if(isOpen) postData.date = getDateFormatFuncServer(date.toLocaleDateString())
        
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/set-student-finaly-date',
            data: postData,
            resolved: (data)=>{
                dispatch(setAdminFinanceChildsDataEdit({id: filtersData.child.id, endDate: isOpen? data.date: null}))
                setFiltersData({...filtersData, child: {...filtersData.child, endDate: isOpen? data.date: null}})
            },
            rejected: (error)=>{                    
                setConfirmLoading(0)
            }
        })
    }

    return(
        <div className={`popup__container admin-finance-popup__documents`} onClick={(e)=>{
            if((e.target.closest(".popup__filters-drop-down-list-container") === null || e.target.classList.contains('popup__filters-drop-down-list-item')) && ( !e.target.classList.contains('admin-finance-popup__documents-top-button') && e.target.closest(".admin-finance-popup__documents-top-document-templates-list") === null && !e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-open'))){
                setOpenDropListName('')
            }
            if((e.target.closest(".admin-finance-popup__documents-line-item-button-refuse-comment") === null && !e.target.classList.contains('admin-finance-popup__documents-line-item-button-refuse'))){
                setCommentIsActive(0)
                setConfirmLoading(0)
                setCommentIsActiveAppId(0)
            }
            if(calendActive && !e.target.classList.contains("admin-popup__sertificate-confirm-line-item-validate-item") && e.target.closest(".admin-popup__sertificate-confirm-calendar") === null){
                setCalendActive(false)
                setCalendPopupPosition({...calendPopupPosition, dateActive: ''})                
            }
        }}>
            <div className="admin-finance-popup__documents-top">
                <div className='admin-finance-popup__documents-top-name'>Документы</div>
                <div className='d-flex align-center'>
                    <DropDownList 
                        listData={childData} 
                        checkedElem={filtersData.child} 
                        clickFunk={(item)=>setFiltersData({...filtersData, child: item})} 
                        listName={'admin-finance-popup__documents-child-drop-list'} 
                        listIsOpen={openDropListName} setListIsOpen={setOpenDropListName}
                        classes={'admin-finance-popup__documents-child-drop-list mr-16'}
                    />

                    <DropDownList 
                        listData={documentsTypeData} 
                        checkedElem={filtersData.documentType} 
                        clickFunk={(item)=>setFiltersData({...filtersData, documentType: item})} 
                        listName={'admin-finance-popup__documents-document-type-drop-list'} 
                        listIsOpen={openDropListName} setListIsOpen={setOpenDropListName}
                        classes={`admin-finance-popup__documents-document-type-drop-list`}
                    />
                </div>
            </div>

            <div className='admin-finance-popup__documents-line title mb-8 d-flex justify-between align-center'>
                <div className='admin-finance-popup__documents-line-item name'>Наименование</div>
                <div className='admin-finance-popup__documents-line-item date'>Дата загрузки</div>
                <div className='admin-finance-popup__documents-line-item status-title'>Статус</div>
                <div className='admin-finance-popup__documents-line-item buttons title'>Действие</div>
            </div>
            
            {
                (filtersData.documentType.id === 3)? (
                    <div className='admin-finance-popup__documents-list'   
                        onScroll={(e)=>scrollingPagination(
                            e, 
                            ()=>setPage(page+1), 
                            ()=>dispatch(fetchAdminFinanceCertificateData({studentId: filtersData.child.id, count: 30, page: page+1, type: 3, isScrolling: true})),
                            pageLast,
                            loading,
                            scrollReset, 
                            ()=>dispatch(setAdminFinanceCertificateScrollReset(false))
                        )}
                    >
                        {
                            certificateData.map(item=> <FinanceCertificateElem confirmLoading={confirmLoading} refuseFunc={popupOpenFunc} item={item} childId={filtersData.child.id} confirmFunc={confirmCertificateFunc} calenDate={{...calendPopupPosition, calendActive: calendActive}} popupOpenCalendFunc={popupOpenCalendFunc} key={`admin-finance-popup__certificztes-list-item-container-${item.id}`}/>)
                        }
                    </div>
                ): (
                    <div className='admin-finance-popup__documents-list'>
                        {
                            (filtersData.documentType.id === 1? documentsData: documentsAdditionalyData).map(item=> 
                                (item.id === "ed9e1e21-10e4-4c49-915e-87883632faab" || item.layout === "ed9e1e21-10e4-4c49-915e-87883632faab")? <FinanceTerminationDocumentElem confirmLoading={confirmLoading} sendFunc={sendDocumentFunc} confirmFunc={confirmDocumentFunc} refuseFunc={popupOpenFunc} deleteFunc={deleteDocumentFunc} childId={filtersData.child.id} endDate={(filtersData.child && filtersData.child.endDate)? filtersData.child.endDate: null} docTypeId={filtersData.documentType.id} item={item} activeList={openDropListName} setActiveList={setOpenDropListName} accessEditFunc={terminationAccesEdit} key={`admin-finance-popup__documents-list-item-container-${item.id}`}/>
                                :<FinanceDocumentElem confirmLoading={(filtersData.documentType.id === 2 && !filtersData.child.isHaveAdditionals && (item.layout === 'e7260596-a357-4f84-8fc6-92fcb47b30d7' || item.layout === '55a9da35-f458-4ce3-9469-07b2ac6c0353'))? 'blocked' :confirmLoading} sendFunc={sendDocumentFunc} confirmFunc={confirmDocumentFunc} refuseFunc={popupOpenFunc} deleteFunc={deleteDocumentFunc} childId={filtersData.child.id} docTypeId={filtersData.documentType.id} item={item} key={`admin-finance-popup__documents-list-item-container-${item.id}`}/>
                            )
                        }
                    </div>
                )
            }

            <RefusalComment style={{top: refusePopupPosition.top, left: refusePopupPosition.left}} setCommentClose={()=>setCommentIsActive(false)} classes={`admin-finance-popup__documents-line-item-button-refuse-comment additionaly-popup-on-top ${commentIsActive? 'active': ''}`} clickFunc={(comment)=>{
                if(filtersData.documentType.id === 3) refuseCertificateFunc(comment)
                else refuseDocumentFunc(comment)
            }}/>
            <Calend style={{top: calendPopupPosition.top, left: calendPopupPosition.left}} classes={`admin-popup__sertificate-confirm-calendar ${calendActive? 'active': ''}`} checkDate={(calendPopupPosition.dateActive === 'start')? getDateFormatFunc(calendPopupPosition.startDate): (calendPopupPosition.dateActive === 'end')? getDateFormatFunc(calendPopupPosition.endDate): null} setCheckDate={(data)=>{
                if(calendPopupPosition.dateActive === 'start') setCalendPopupPosition({...calendPopupPosition, startDate: data.toLocaleDateString(), dateActive: ''}) 
                if(calendPopupPosition.dateActive === 'end') setCalendPopupPosition({...calendPopupPosition, endDate: data.toLocaleDateString(), dateActive: ''}) 
                setCalendActive(false)
            }}
            />
        </div>
    )
}

export default FinanceDocument;