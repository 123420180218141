import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import './index.scss'
import { ChildClass, City, School, MonthYear, Programs, SubGroup, TeachersList } from '../../../../Filters/FiltersList';
import { selectAdminTabel } from './../../../../../redux/admin/tabel/selectors';
import { setAdminTabelFilters } from '../../../../../redux/admin/tabel/tabelSlice';
import teacher from '../../../../../redux/teacher';



const TabelFilters = ({popupBack, closePopup}) =>{
    const dispatch = useDispatch()
    const [activeList, setActiveList] = useState('')
    const { adminTabelFilters:filters } = useSelector(selectAdminTabel);

    const [error, setError] = useState('')

    const [formData, setFormData] = useState({
        city: {id: 0, name: ''},
        school: {id: 0, name: ''},
        classObj: {id: 0, number: '', letter: ''},
        subgroup: {id: 0, name: ''},
        teacher: {id: 0, name: ''},
        program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
        month: {id: 0, label: ''},
        year: 0,
        post: {},
    })

    const [searchData, setSearchData] = useState({
        city: '',
        school: '',
        teacher: ''
    })

    useEffect(()=>{
        setFormData({
            ...filters,
            classNumber: filters.classObj.number,
            classLetter: filters.classObj.letter,
        })
        setSearchData({
            city: '',
            school: '',
            teacher: ''
        })
        setActiveList('')

        setError('')
    }, [popupBack])

    const saveFilters = () =>{
        setError('')
        let filterActiveData = {
            city: formData.city,
            school: formData.school,
            classObj: formData.classObj,
            subgroup: formData.subgroup,
            teacher: formData.teacher, 
            program: formData.program, 
            month: formData.month,
            year: formData.year,
        }
        let filtersPostData = {
            cityId: formData.city.id,
            schoolId: formData.school.id,
            classId: formData.classObj.id,
            teacherId: formData.teacher.id,
            programId: formData.program.id,
            month: formData.month.id,
            year: formData.year,
        }

        if(formData.program.isContainsSubgroup) filtersPostData.groupId = formData.subgroup.id;

        // if(formData.city.id && formData.school.id && formData.classObj.number && formData.classObj.letter && formData.teacher.id && formData.program.id && (!formData.program.isContainsSubgroup || formData.subgroup.id) && formData.month.id && formData.year){
        if(formData.city.id && formData.school.id && formData.classObj.number && formData.classObj.letter && formData.program.id && (!formData.program.isContainsSubgroup || formData.subgroup.id) && formData.month.id && formData.year){
            dispatch(setAdminTabelFilters({...filterActiveData, post: filtersPostData}))
            closePopup()
        }
        else{
            setError('Необходимо заполнить все поля*')
        }
    }

    const cleanFilters = () =>{         
        dispatch(setAdminTabelFilters({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classObj: {id: 0, number: '', letter: ''},
            subgroup: {id: 0, name: ''},
            teacher: {id: 0, name: ''},
            program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
            month: {id: 0, label: ''},
            year: new Date().getFullYear(),
            post: {},
        }))
        setFormData({
            city: {id: 0, name: ''},
            school: {id: 0, name: ''},
            classObj: {id: 0, number: '', letter: ''},
            subgroup: {id: 0, name: ''},
            teacher: {id: 0, name: ''},
            program: {id: 0, name: '', teacherName: '', isContainsSubgroup: false}, 
            month: {id: 0, label: ''},
            year: new Date().getFullYear(),
            post: {},
        })
        closePopup()
    }

    return(
        <div className={`popup__container admin-popup__container finanse-page-history-filter`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__drop-list-checked-input') && !e.target.classList.contains('popup__drop-list-elem-checkbox') && !e.target.classList.contains('popup__drop-list-open')  && !e.target.classList.contains('popup__input')){
                setActiveList('')
                setSearchData({
                    city: '',
                    school: '',
                    teacher: '',
                })
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title'>
                    Фильтры
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <City
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                recalculate={true}
                url='/api/v1/administrative/programs'
            />

            <School
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                recalculate={true}
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <ChildClass 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}                
                setError={setError}
                url='/api/v1/administrative/programs'
            />

            <MonthYear
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}                
                setError={setError}
                // classes={''}
            />

            <TeachersList
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative'
                isFilter={true}
            />

            <Programs
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
                isTabel={true}
                openTop={true}
            />

            <SubGroup
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData} 
                searchData={searchData} 
                setSearchData={setSearchData}
                setError={setError}
                url='/api/v1/administrative/programs'
            />
            
            <div className='popup__error'>{error}</div>

            <div className='d-flex'>
                <button className='popup__button wd-50-mr-16 cancel' onClick={cleanFilters}>Очистить</button>
                <button className={`popup__button wd-50-mr-16 save ${!(formData.city.id && formData.school.id && formData.classObj.number && formData.classObj.letter && formData.program.id && (!formData.program.isContainsSubgroup || formData.subgroup.id) && formData.month.id && formData.year)? 'deactive-button': ''}`} onClick={saveFilters}>Применить</button>
            </div>       
        </div>
    )
}

export default TabelFilters;