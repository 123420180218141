import FinanceClientsFilters from './FinanceClientsFilters';
import PaymentHistoryFilters from './PaymentHistoryFilters';
import UserPaymentHistoryFilters from './UserPaymentHistoryFilters/index';
import FinanceEditChild from './FinanceEditChild';
import FinanceDocument from './FinanceDocument';
import FinanceExport1C from './FinanceExport1C/FinanceExport1C';
import FinanceMatcapPayment from './FinanceMatcapPayment';

const FinancePopup = ({unDisplay, popupBack, type, props}) =>{
    return(
        (type === "FinanceHistoryFilters")? <PaymentHistoryFilters closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "FinanceClientsFilters")? <FinanceClientsFilters closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "UserCardHistoryFilters")? <UserPaymentHistoryFilters closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "FinanceEditChild")? <FinanceEditChild closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "FinanceDocuments")? <FinanceDocument closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "FinanceExport1C")? <FinanceExport1C closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        :(type === "FinanceMatcapPayment")? <FinanceMatcapPayment closePopup={unDisplay} popupBack={popupBack} type={type} setPopupType={props.typeEdit}/>
        : null
    )
}

export default FinancePopup;