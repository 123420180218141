import { createSlice } from '@reduxjs/toolkit';


const initialState = {
   user: (localStorage.getItem('save-autorize')  === 'true' && localStorage.getItem('AthorizationUserData') !== null)? JSON.parse(localStorage.getItem('AthorizationUserData')): (sessionStorage.getItem('AthorizationUserData') !== null)? JSON.parse(sessionStorage.getItem('AthorizationUserData')): {
    id: 0,
    token: '',
    role: '',
   }
};



const authorization = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setUserData(state, action) {
        state.user = action.payload;
        if(action.payload.save){
          localStorage.setItem('AthorizationUserData', JSON.stringify(action.payload))
          localStorage.setItem('save-autorize', true)
        } 
        else {
          sessionStorage.setItem('AthorizationUserData', JSON.stringify(action.payload))
          localStorage.setItem('save-autorize', false)
        }
        

        if(!action.payload.token){
          localStorage.setItem('AthorizationUserData', JSON.stringify(action.payload))
          sessionStorage.setItem('AthorizationUserData', JSON.stringify(action.payload))
          localStorage.setItem('save-autorize', false)
        }
    },
  },
});

const { actions, reducer } = authorization;


export default reducer;

export const { 
    setUserData
} = actions;

