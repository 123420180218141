import DocumentElem from './../DocumentElem/index';
import DocumentDefaultElem from './../DocumentDefaultElem/index';
import { useEffect, useRef } from 'react';
import { setBlockedDocumentsDelete, setSelectionDocument } from '../../../../redux/users/documents/documentsSlice';
import { useDispatch } from 'react-redux';
import { setUserPopupBack } from '../../../../redux/users/popup/userPopupSlice';
import TerminationDocumentElem from '../TerminationDocumentElem';

const DocumentsContainer = ({keyVal, title, documents, sendFunc, docTypeId, checkedChild, selectionDocument, access, blockedDocuments, terminationDocumentLoadFunc=()=>{}}) =>{
    const dispatch = useDispatch()
    const additionalyDocRef = useRef()

    useEffect(()=>{
        if(selectionDocument && docTypeId === 2){
            dispatch(setBlockedDocumentsDelete('4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b'))
            additionalyDocRef.current.scrollIntoView({
                behavior: "smooth"
            });
            dispatch(setUserPopupBack('open'))        
            setTimeout(()=>{
                dispatch(setSelectionDocument(''))
                dispatch(setUserPopupBack(''))
            }, 5000)
        }     
    }, [selectionDocument])

    return (
        <div className={`user-page__back-container documents documents-fixed-height ${selectionDocument? 'selection-block': ''}`}  ref={(selectionDocument && docTypeId === 2)? additionalyDocRef: null} id={`${docTypeId === 2? 'mat-cap-active': ''}`} key={keyVal}>
            <div className='user-page__title back-container-title'>{title}</div>
            {
                documents.map((item)=>
                    (item.id === "ed9e1e21-10e4-4c49-915e-87883632faab" || item.layoutId === "ed9e1e21-10e4-4c49-915e-87883632faab")?
                    
                    <TerminationDocumentElem 
                        key={keyVal + '-' + item.id} 
                        docTypeId={docTypeId}
                        checkedChild={checkedChild} 
                        downloadOrUploadFunc={terminationDocumentLoadFunc}
                        sendFunc={sendFunc}  
                        keyVal={keyVal} 
                        item={item} 
                        access={access}
                    />

                    :<DocumentElem 
                        key={keyVal + '-' + item.id} 
                        docTypeId={docTypeId} 
                        selectionDocument={selectionDocument} 
                        blockedDocuments={blockedDocuments} 
                        checkedChild={checkedChild} 
                        sendFunc={sendFunc} 
                        keyVal={keyVal} 
                        item={item} 
                        access={access}
                    />
                )
            }
            {
                (documents.length < 3)? (
                    <>
                        {
                            [1, 2, 3,].slice(documents.length).map(item=><DocumentDefaultElem sendBtn={true} key={`${keyVal}-default-${item}`} keyVal={`${keyVal}-default-${item}`}/>)
                        }
                    </>
                ): null
            }
        </div>
    )
}

export default DocumentsContainer;