import { useState, useEffect } from 'react';
import './index.scss'
import { selectDropDownListData } from '../../../redux/dropDownList/selectors';
import { useDispatch, useSelector } from 'react-redux';
import DropDownList from '../DropDownList';
import { setDropListName } from '../../../redux/dropDownList/dropDownListSlice';
import DocumentsContainer from './DocumentsContainer';
import AegrotatsContainer from './AegrotatContainer';
import { setUserPopupBack } from '../../../redux/users/popup/userPopupSlice';
import UserPopup from './../Popup/UserPopup';
import { selectUserDocuments } from './../../../redux/users/documents/selectors';
import { setUserAdditionallyDocumentsElemEdit, setUserAegrotatsScrollReset, setUserDocumentsElemEdit } from '../../../redux/users/documents/documentsSlice';
import { selectUserChildren } from './../../../redux/users/children/selectors';
import { fetchUserAegrotatsData, fetchUserDocumentsData } from '../../../redux/users/documents/asyncActions';
import { convertNumber, downloadFilePost, scrollingPagination } from '../../../services/clientHome';
import { axiosProxyCatchGet, axiosProxyCatchPost } from '../../../axiosProxy';
import { fetchUserChildsData } from '../../../redux/users/children/asyncActions';
import { setUserProfile } from '../../../redux/users/profile/profileSlice';
import { selectUserProfile } from './../../../redux/users/profile/selectors';
import { fetchUserProfileData } from './../../../redux/users/profile/asyncActions';
import { setUserNavBarActive } from '../../../redux/users/navigations/userNavigationsSlice';
import TerminationDocumentWarningBlock from '../Popup/TerminationDocumentWarningBlock/TerminationDocumentWarningBlock';

const Documents = () =>{
    const dispatch = useDispatch();

    const { listName } = useSelector(selectDropDownListData)
    const { documentsData, additionallyDocumentsData, aegrotatsData, loading, pageLast, scrollReset, selectionDocument, blockedDocuments } = useSelector(selectUserDocuments)
    const { profileData } = useSelector(selectUserProfile);

    const { homeChildrenData, checkChild } = useSelector(selectUserChildren);

    const [terminationWarning, setTerminationWarning] = useState({
        refundOverPaymentStudents: [],
        type: '',
        subtitle: '',
    })

    const [page, setPage] = useState(0)

    const [checkedChild, setCheckedChild] = useState((homeChildrenData.length && homeChildrenData[0].name)? {...homeChildrenData[0], label: homeChildrenData[0].name.split(' ')[1]}: {
        id: 0, 
        name: '',
        classObj: {id: 0, number: '', letter: ''}, 
        birthDay: '',
        city: {id: 0, name: ''}, 
        school: {id: 0, name: ''},
        recalculate: false
    })
    const [popupType, setPopupType] = useState('')

    const childrenCheckedFunc = (item) =>{
        dispatch(setDropListName(''))
        setCheckedChild({...item, label: item.name.split(' ')[1]})
    }

    const addAegrotatFunc = () =>{
        dispatch(setUserPopupBack('open'))
        setPopupType('AddAegrotat')
    }

    const sendDocumentsFunc = (file, docTypeId, setLoading) =>{
        
        let postData = {
            file: file.fileBytes,
            extension: file.extension,
            layoutId: file.id,
            studentId: checkedChild.id,
        }
        // setLoading(true)
        axiosProxyCatchPost({
            url:'/api/v1/parental/document/create-document', 
            data: postData,
            resolved: (response) =>{
                setLoading(false)
                
                if(docTypeId === 1){                    
                    dispatch(setUserDocumentsElemEdit({
                        id: file.id,
                        documentId: response.id,
                        size: file.size, 
                        docType: file.extension, 
                        status: 0,
                        link: true
                        // documentLink: file.link,
                    }))
                }
                else if(docTypeId === 2){                    
                    dispatch(setUserAdditionallyDocumentsElemEdit({
                        id: file.id, 
                        documentId: response.id,
                        size: file.size, 
                        docType: file.extension, 
                        status: 0,
                        link: true
                        // documentLink: file.link,
                    }))
                }
            },
            rejected: (error) =>{
                setLoading(false)
            },
        })
        
    }

    const sendOrDownoloadTerminationDocumentFunc = ({isDownload, sendFunc, downloadFunc, status}) =>{
        if(typeof status !== 'number'){
            axiosProxyCatchGet({
                url: `/api/v1/parental/profile/get-finance-position?id=${checkedChild.id}`,
                resolved:({data:{data}})=>{                                        
                    if(data.summ === 0 || (data.summ > 0 && terminationWarning.refundOverPaymentStudents.indexOf(checkedChild.id) !== -1)){
                        if(isDownload) downloadFunc()
                        else sendFunc()
                    }
                    else if (data.summ > 0){
                        setTerminationWarning({
                            ...terminationWarning, 
                            type: 'OverPayment',
                            subtitle: `У Вас имеется переплата за обучение в размере ${convertNumber(data.summ)} руб. Пожалуйста, свяжитесь с администрацией для возвращения Ваших средств. После возврата средств нажмите на кнопку "Средства возвращены" и документ откроется.`
                        })
                    }
                    else if (data.summ < 0){
                        setTerminationWarning({
                            ...terminationWarning, 
                            type: 'Debt',
                            subtitle: `У Вас имеется задолженность по оплате в размере ${convertNumber(data.summ * -1)} руб. Пожалуйста, совершите оплату и документ станет доступным`
                        })
                    }
                },
                rejected: ()=>{
    
                }
            })
        }
        else{
            if(isDownload) downloadFunc()
            else sendFunc()            
        }
    }

    useEffect(()=>{
        if(checkChild.id && homeChildrenData.length > 1 && selectionDocument){
            let checkChildElem = homeChildrenData.filter(item=>(item.id === checkChild.id))
            setCheckedChild((checkChildElem.length)? {...checkChildElem[0], label: checkChildElem[0].name.split(' ')[1]}: {
                id: 0, 
                name: '',
                classObj: {id: 0, number: '', letter: ''}, 
                birthDay: '',
                city: {id: 0, name: ''}, 
                school: {id: 0, name: ''},
                recalculate: false,
            })
        }
        else{
            setCheckedChild((homeChildrenData.length && homeChildrenData[0].name)? {...homeChildrenData[0], label: homeChildrenData[0].name.split(' ')[1]}: {
                id: 0, 
                name: '',
                classObj: {id: 0, number: '', letter: ''}, 
                birthDay: '',
                city: {id: 0, name: ''}, 
                school: {id: 0, name: ''},
                recalculate: false,
            })
        }
    }, [homeChildrenData])

    useEffect(()=>{
        dispatch(fetchUserChildsData())
        dispatch(setUserNavBarActive(1))
    }, [])

    useEffect(()=>{
        if(checkedChild.id){
            dispatch(fetchUserProfileData())
            dispatch(fetchUserDocumentsData({childId: checkedChild.id}))
            if(checkedChild.recalculate) dispatch(fetchUserAegrotatsData({count: 10, page: 0, isScrolling: false, childId: checkedChild.id}))
        }
    }, [checkedChild])

    return (
        <div className={`user-page__documents`}>
            <div className='d-flex justify-between align-center mt-50 mb-24'>
                <div className='user-page__title documnets-title' onClick={()=>{
                    dispatch(setUserProfile({financeAccess: false}))
                }}>Документы</div>
                <DropDownList listData={homeChildrenData.map(item=>{return {...item, label: item.name.split(' ')[1]}})} label='label' personName={true} checkedElem={checkedChild} clickFunk={(item)=>childrenCheckedFunc(item)} listName={'user-page__documents-children-drop-list'} listIsOpen={listName} setListIsOpen={(name)=>dispatch(setDropListName(name))}/>
            </div>
            <DocumentsContainer keyVal='basic-documents' title='Основные' documents={documentsData} sendFunc={sendDocumentsFunc} checkedChild={checkedChild} docTypeId={1}/>
            
            {
                (additionallyDocumentsData.length !== 0)?<DocumentsContainer terminationDocumentLoadFunc={sendOrDownoloadTerminationDocumentFunc} access={profileData.financeAccess} keyVal='additionally-documents' title='Дополнительные'  checkedChild={checkedChild} selectionDocument={selectionDocument} blockedDocuments={blockedDocuments} documents={additionallyDocumentsData} sendFunc={sendDocumentsFunc} docTypeId={2}/>
                : null
            }

            {
                (checkedChild.recalculate)? 
                <AegrotatsContainer 
                    keyVal='aegrotats' 
                    title='Справки о болезни' 
                    aegrotats={aegrotatsData} 
                    sendFunc={addAegrotatFunc}
                    pageLast={pageLast}
                    onScrollFunc={
                        (e)=>scrollingPagination(
                            e, 
                            ()=>setPage(page+1), 
                            ()=>dispatch(fetchUserAegrotatsData({count: 10, page: page+1, isScrolling: true, childId: checkedChild})), 
                            pageLast,
                            loading,
                            scrollReset, 
                            ()=>dispatch(setUserAegrotatsScrollReset(false))
                        )
                    }
                />: null
            }
            
            <UserPopup type={popupType} props={{typeEdit: setPopupType, checkedChild: checkedChild}}/>
            <TerminationDocumentWarningBlock 
                popupBack={(terminationWarning.type === 'OverPayment')? 'open': ''} 
                props={
                    {
                        title:'Переплата', 
                        subtitle: terminationWarning.subtitle, 
                        closePopup: (val)=>setTerminationWarning({...terminationWarning, type: val}),
                        refundOverPaymentFunc: () => setTerminationWarning({...terminationWarning, type: '', refundOverPaymentStudents: [...terminationWarning.refundOverPaymentStudents, checkedChild.id]}),
                        type: 'OverPayment'
                    }
                }
            />
            <TerminationDocumentWarningBlock 
                popupBack={(terminationWarning.type === 'Debt')? 'open': ''} 
                props={
                    {
                        title: 'Задолженность', 
                        subtitle: terminationWarning.subtitle, closePopup: (val)=>setTerminationWarning({...terminationWarning, type: val}),
                        type: 'Debt'
                    }
                }
            />
        </div>
    )
}

export default Documents;