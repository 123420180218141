import './index.scss'
import { getStatus } from '../../../../User/Documents/DocumentElem';
import { useEffect, useState } from 'react';
import RefusalComment from '../../RefusalComment';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';
import { fetchAdminConfirmDocumentAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { downloadFilePost, getDocumentType } from '../../../../../services/clientHome';

const DocumentConfirm = ({closePopup, popupBack}) =>{    
    const dispatch = useDispatch();

    const { adminConfirmDocumentAplication:documentData } = useSelector(selectAdminAplicationConfirm);    

    const [commentIsActive, setCommentIsActive] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)

    const confirmAndRefuseFunc = (confirm, comment='') =>{
        const postData = {
            id: documentData.document.id,
            // documentId: documentData.document.id,
            status: confirm? 1: -1,
            documentStatus: documentData.document.status
        }
        if(!confirm && comment) postData.comment = comment;
        setConfirmLoading(true)
        setCommentIsActive(false)
        
        if(documentData.document.layoutId && documentData.document.layoutId === '4c7b3f72-bfe6-4d7b-ac31-f2dc4d5a197b' && confirm){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/accept-mat-cap',
                data: {documentId: postData.id},
                resolved: (data)=>{
                    DocumentConfirmPost(postData)
                },
                rejected: (error)=>{
                    setConfirmLoading(false)                    
                }
            })
        }
        else if(documentData.document.layoutId && documentData.document.layoutId === "ed9e1e21-10e4-4c49-915e-87883632faab" && confirm){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/accept-finish-document',
                data: {documentId: postData.id},
                resolved: (data)=>{
                    DocumentConfirmPost(postData)
                },
                rejected: (error)=>{
                    setConfirmLoading(0)
                }
            })
        }
        else{
            DocumentConfirmPost(postData)
        }
        
    }

    // const DocumentConfirmPost = (postData) => {
    //     axiosProxyCatchPost({
    //         url: '/api/v1/administrative/applications/update-document-status',
    //         data: postData,
    //         resolved: (data)=>{
    //             axiosProxyCatchPost({
    //                 url: '/api/v1/administrative/applications/update-application-status',
    //                 data: { ...postData, id: documentData.id},
    //                 resolved: (data)=>{
    //                     dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
    //                     closePopup()
    //                     setConfirmLoading(false)
    //                 },
    //                 rejected: (error)=>{
    //                     setConfirmLoading(false)                        
    //                 }
    //             })
    //         },
    //         rejected: (error)=>{
    //             setConfirmLoading(false)                
    //         }
    //     })
    // }

    const DocumentConfirmPost = (postData) => {
        axiosProxyCatchPost({
            url: '/api/v1/administrative/applications/update-document-status',
            data: postData,
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: { ...postData, id: documentData.id},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                        closePopup()
                        setConfirmLoading(false)
                    },
                    rejected: (error)=>{
                        setConfirmLoading(false)                        
                    }
                })
            },
            rejected: (error)=>{
                setConfirmLoading(false)                
            }
        })

        if(postData.documentStatus === null){
            axiosProxyCatchPost({
                url: '/api/v1/administrative/applications/update-application-status',
                data: { ...postData, id: documentData.id},
                resolved: (data)=>{
                    dispatch(setAdminAplicationsDataDeleteElem(documentData.id))
                    closePopup()
                    setConfirmLoading(false)
                },
                rejected: (error)=>{
                    setConfirmLoading(false)                        
                }
            })
        }
    }

    useEffect(()=>{
        if(documentData.id) dispatch(fetchAdminConfirmDocumentAplication({id: documentData.id}))
    }, [popupBack])

    return(
        <div className={`popup__container admin-popup__documnet-confirm`} onClick={(e)=>{
            if(e.target.closest(".admin-popup__documnet-confirm-button-container") === null){
                setCommentIsActive(false)
            }
        }}>
            <div className="admin-popup__documnet-confirm-top">
                <div className='admin-popup__documnet-confirm-top-name'>{documentData.clientName}</div>
                <div className='admin-popup__documnet-confirm-top-type'>{getDocumentType(documentData.type).label}</div>
            </div>

            <div className='admin-popup__documnet-confirm-line title mb-8 d-flex justify-between align-center'>
                <div className='admin-popup__documnet-confirm-line-item name'>Наименование</div>
                <div className='admin-popup__documnet-confirm-line-item date'>Дата загрузки</div>
                <div className='admin-popup__documnet-confirm-line-item status-title'>Статус</div>
                <div className='admin-popup__documnet-confirm-line-item buttons'>Действие</div>
            </div>
            
            <div className='admin-popup__documnet-confirm-line mb-16 d-flex justify-between align-center'>
                <div className='admin-popup__documnet-confirm-line-item name d-flex align-center'>
                    <div className='admin-popup__documnet-confirm-line-item-document-icon'/>
                    <div className='admin-popup__documnet-confirm-line-item-name'>{documentData.document.name}</div>
                </div>
                <div className='admin-popup__documnet-confirm-line-item date'>{documentData.document.date}</div>
                <div className={`admin-popup__documnet-confirm-line-item status ${getStatus(documentData.document.status).color}`}>{getStatus(documentData.document.status).label}</div>
                <div className='admin-popup__documnet-confirm-line-item buttons d-flex justify-end align-center'>
                    {/* <a className='admin-popup__documnet-confirm-line-item-button download' href={documentData.document.link} download/> */}
                    <div className={`admin-popup__documnet-confirm-line-item-button download ${downloadLoading? 'deactive-button': ''}`} onClick={() => {
                        if(documentData.document.id){
                            downloadFilePost({url:'/api/v1/administrative/finance/get-file?', name: documentData.document.name, type: (documentData.document.extesion.indexOf('.') === -1)? '.'+documentData.document.extesion: (documentData.document.extesion)? documentData.document.extesion: '', postData: {documentId: documentData.document.id}, setLoading: setDownloadLoading})
                        }
                    }}/>
                </div>
            </div>
            
            <div className='d-flex'>
                <div className={`admin-popup__documnet-confirm-button-container wd-50-mr-16 ${confirmLoading? 'deactive-button': ''}`}>
                    <button className='popup__button cancel admin-popup__documnet-confirm-button-cancel' onClick={()=>setCommentIsActive(true)}>Отклонить</button>
                    <RefusalComment setCommentClose={()=>setCommentIsActive(false)} clickFunc={(comment)=>confirmAndRefuseFunc(false, comment)} classes={`admin-popup__documnet-confirm-button-comment additionaly-popup-on-top ${commentIsActive? 'active': ''}`}/>
                </div>
                <button className={`popup__button wd-50-mr-16 save ${confirmLoading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(true)}>Подтвердить</button>
            </div>
        </div>
    )
}

export default DocumentConfirm;