// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popup__payment-block {
  width: 100%;
  height: 54px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  font-size: 18px;
  font-weight: 500;
  transition: .3s all;
  cursor: pointer; }
  .user-popup__payment-block.active {
    border-color: #B158FF; }
  .user-popup__payment-block-val {
    font-weight: 600; }

.user-popup__payment-arbitrary-summ {
  overflow: hidden;
  height: 0px;
  transition: .3s all; }
  .user-popup__payment-arbitrary-summ.active {
    margin-top: 24px;
    height: 78px; }

.user-popup__payment-input-block {
  width: 100%;
  height: 54px;
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  position: relative; }
  .user-popup__payment-input-block-val, .user-popup__payment-input-block-input {
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600; }
  .user-popup__payment-input-block-input {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 1;
    opacity: 0; }
  .user-popup__payment-input-block-input:focus {
    opacity: 1; }
  .user-popup__payment-input-block-input:focus + .user-popup__payment-input-block-val {
    opacity: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/User/Popup/Payment/index.scss"],"names":[],"mappings":"AACI;EACI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,eAAe,EAAA;EATlB;IAYO,qBAAqB,EAAA;EAGzB;IACI,gBAAgB,EAAA;;AAIxB;EACI,gBAAgB;EAChB,WAAW;EACX,mBAAmB,EAAA;EAHtB;IAMO,gBAAgB;IAChB,YAAY,EAAA;;AAIpB;EACI,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB,EAAA;EAElB;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,gBAAgB,EAAA;EAGpB;IACI,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,UAAU;IACV,UAAU,EAAA;EAGd;IACI,UAAU,EAAA;EAEd;IACI,UAAU,EAAA","sourcesContent":[".user-popup__payment{\n    &-block{\n        width: 100%;\n        height: 54px;\n        padding: 16px;\n        border-radius: 8px;\n        border: 1px solid #E6E6E6;\n        font-size: 18px;\n        font-weight: 500;\n        transition: .3s all;\n        cursor: pointer;\n\n        &.active{\n            border-color: #B158FF;\n        }\n\n        &-val{\n            font-weight: 600;\n        }\n    }\n\n    &-arbitrary-summ{\n        overflow: hidden;\n        height: 0px;\n        transition: .3s all;\n\n        &.active{\n            margin-top: 24px;\n            height: 78px;\n        }\n    }\n\n    &-input-block{\n        width: 100%;\n        height: 54px;\n        border-radius: 8px;\n        border: 1px solid #E6E6E6;\n        position: relative;\n\n        &-val, &-input{\n            width: 100%;\n            height: 100%;\n            padding: 16px;\n            border-radius: 8px;\n            font-size: 18px;\n            font-weight: 600;\n        }\n\n        &-input{\n            position: absolute;\n            top: 0px;\n            left: 0;\n            z-index: 1;\n            opacity: 0;\n        }\n\n        &-input:focus{\n            opacity: 1;\n        }\n        &-input:focus + &-val{\n            opacity: 0;\n        }\n\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
