import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { fetchAdminConectedProgramAplication } from '../../../../../redux/admin/aplications/aplicationConfirm/asyncActions';
import { selectAdminAplicationConfirm } from '../../../../../redux/admin/aplications/aplicationConfirm/selectors';

import './index.scss'
import { axiosProxyCatchPost } from './../../../../../axiosProxy';
import { setAdminAplicationsDataDeleteElem } from '../../../../../redux/admin/aplications/aplications/aplicationsSlice';
import { getDateFormatFuncServer, getMonthName } from '../../../../../services/clientHome';
import { TerminationMonth } from '../../../../Filters/FiltersList';


const ProgramConnection = ({popupBack, closePopup, type}) =>{
    const dispatch = useDispatch();

    const { adminConectedProgramAplication:programData } = useSelector(selectAdminAplicationConfirm);

    const [formData, setFormData] = useState({
        month: {
            id: 0,
            date: '',
            label: '',
        }
    })

    const [activeList, setActiveList] = useState('')

    const [error, setError] = useState('')

    const [loading, setLoading] = useState(false)
    
    useEffect(()=>{
        dispatch(fetchAdminConectedProgramAplication({id: programData.applicationId}))      
        setFormData({
            month: {
                id: 0,
                date: '',
                label: '',
            }
        }) 
        setError('')
        setActiveList('')
        setLoading(false)
    }, [popupBack])

    useEffect(()=>{
        let date = new Date(programData.date);
        setFormData({month: {
            id: date.getMonth() + 1,
            label: getMonthName(date.getMonth() + 1),
            date: date,
        }})        
    }, [programData])

    const confirmAndRefuseFunc = (confirm) =>{
        const postData = {
            id: programData.id,
            status: confirm,
        }

        if(programData.status === 1) postData.startDate = getDateFormatFuncServer(formData.month.date.toLocaleDateString());
        else postData.endDate = getDateFormatFuncServer(formData.month.date.toLocaleDateString());

        setLoading(true)
        axiosProxyCatchPost({
            url: '/api/v1/administrative/finance/update-additional',
            data: postData,
            resolved: (data)=>{
                axiosProxyCatchPost({
                    url: '/api/v1/administrative/applications/update-application-status',
                    data: {id: programData.applicationId, status: confirm? 1: -1},
                    resolved: (data)=>{
                        dispatch(setAdminAplicationsDataDeleteElem(programData.applicationId))
                        closePopup()
                        setLoading(false)
                    },
                    rejected: (error)=>{
                        if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                        else setError('Что-то пошло не так*')
                        setLoading(false)                        
                    }
                })
            },
            rejected: (error)=>{
                if(error && error.data && error.data.detail && typeof error.data.detail === 'string') setError(error.data.detail)
                else setError('Что-то пошло не так*')
                setLoading(false)                
            }
        })
    }

    return(
        <div className={`popup__container admin__popup-program-connection`} onClick={(e)=>{
            if(!e.target.classList.contains('popup__drop-list-checked') && !e.target.classList.contains('popup__button') && e.target.closest('.popup__drop-list-checked') === null){
                setActiveList('')
            }
        }}>
            <div className='d-flex justify-between align-center mb-24'>
                <div className='wd-24'/>
                <div className='popup__title fs-24'>
                    {(programData.status === 1)? 'Подключение программы' :'Отключение программы'}
                </div>
                <div className='popup__close' onClick={closePopup}/>
            </div>

            <label className="popup__label mb-4">ФИО ребенка</label>
            <div className="popup__input mb-16">{programData.student.name}</div>

            <label className="popup__label mb-4">Город</label>
            <div className="popup__input mb-16">{programData.city.name}</div>

            <label className="popup__label mb-4">Школа</label>
            <div className="popup__textarea scool mb-16">{programData.school.name}</div>

            <label className="popup__label mb-4">Класс</label>
            <div className="popup__input mb-16">{programData.classObj.number}{programData.classObj.letter}</div>

            <label className="popup__label mb-4">Программа</label>
            <div className="popup__input mb-16 d-flex align-center">
                <div className={`admin__popup-program-connection-program-item ${programData.program.color}`}>{programData.program.name}</div>
            </div>

            <TerminationMonth 
                activeList={activeList} 
                setActiveList={setActiveList} 
                formData={formData} 
                setFormData={setFormData}
                setError={setError}
                classes={``}
                isLastBlock={true}
                label={'Месяц'}
            />

            <div className='popup__error'>{error}</div>

            <div className='d-flex mt-24'>
                <button className={`popup__button wd-50-mr-16 cancel ${loading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(false)}>Отклонить</button>
                <button className={`popup__button wd-50-mr-16 save ${loading? 'deactive-button': ''}`} onClick={()=>confirmAndRefuseFunc(true)}>Принять</button>
            </div>
        </div>
    )
}

export default ProgramConnection;